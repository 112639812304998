import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * ISO-8601 compliant DateTime
   *
   * e.g.: `1996-12-19T16:39:57-08:00`
   */
  DateTime: any;
  /** A valid email address per RFC 5322 spec */
  Email: any;
  /**
   * Integrated Circuit Card Identifier
   *
   * *TODO length validation is not implemented, for the sake of testing any string will be accepted*
   *
   * A 19-20 Digit Number that uniquely identifies a Sim Card.
   *
   * ICCID can consist of numbers and dashes. Dashes get stripped and are used just to ease data entry.
   *
   * e.g.: `8991101200003204514` or `89911-01200-00320-4514` or `8991-1012-0000-3204-514` are valid ICCIDs.
   *
   * However, no matter where the dashes are, the ICCID will always be returned back without dashes, e.g.
   * `8991101200003204514`
   */
  ICCID: any;
  /** Strong password requirements apply */
  Password: any;
};





/** Accounts have users, own devices, and have invoices */
export type Account = Node & Timestamps & {
  __typename?: 'Account';
  /** Account number is the number Astronautics uses to manage this account */
  accountNumber?: Maybe<Scalars['String']>;
  actions: Array<DeviceAction>;
  /** Service Accounts that can Access the API */
  apiCredentials: Array<ApiCredential>;
  created: Scalars['DateTime'];
  /** Total Data Usage for account (sum of data usage across all SIMs in account) */
  dataUsage: TimeSeries;
  /**
   * Used to select a single device, must provide one of the following:
   *
   * - `deviceId`
   * - `iccid`
   * - `deviceSerialNumber`
   * - `aircraftSerialNumber` and `aircraftType`
   */
  device?: Maybe<Device>;
  deviceSummary: DeviceSummary;
  /** Get all devices for an account */
  devices: DeviceResults;
  id: Scalars['ID'];
  /** A list of invoices */
  invoices: Array<Invoice>;
  /**
   * - `false` when an account is active
   * - `true` when an account has been deactivated
   *
   * Only Astronautics admins are able to access a deactivated account
   */
  isDeactivated: Scalars['Boolean'];
  modified: Scalars['DateTime'];
  /** Display name of an account (e.g. Airbus) */
  name: Scalars['String'];
  /** The Person to Contact if there is a problem */
  primaryContact?: Maybe<Address>;
  /** Users within an Account */
  users: Array<UserAccountEdge>;
};


/** Accounts have users, own devices, and have invoices */
export type AccountActionsArgs = {
  since?: Maybe<Scalars['DateTime']>;
};


/** Accounts have users, own devices, and have invoices */
export type AccountDataUsageArgs = {
  start: Scalars['DateTime'];
  stop: Scalars['DateTime'];
  interval: TimeInterval;
};


/** Accounts have users, own devices, and have invoices */
export type AccountDeviceArgs = {
  deviceId?: Maybe<Scalars['ID']>;
  iccid?: Maybe<Scalars['ICCID']>;
  deviceSerialNumber?: Maybe<Scalars['String']>;
  aircraftSerialNumber?: Maybe<Scalars['String']>;
  aircraftType?: Maybe<Scalars['String']>;
};


/** Accounts have users, own devices, and have invoices */
export type AccountDevicesArgs = {
  sortBy?: Maybe<DeviceSort>;
  filter?: Maybe<DeviceFilterInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};

export type AccountInput = {
  /** UUID */
  id: Scalars['ID'];
  /** The unique id that astronautics uses to identify this account by */
  accountNumber?: Maybe<Scalars['String']>;
  /** The name of the account */
  name?: Maybe<Scalars['String']>;
};

/** Role of a user in an account */
export enum AccountRole {
  /**
   * An account Admin has the same access as an account User, but also the following abilities:
   *
   * - Add or Remove Users
   * - Create or Revoke API Credentials
   * - Update Account Contact Information
   */
  Owner = 'Owner',
  /** A User can create, view, edit devices and see data usage and invoice data */
  User = 'User'
}

/** An address is a person and place */
export type Address = Node & {
  __typename?: 'Address';
  id: Scalars['ID'];
  /** typically the recipient, e.g. `Accts Receiving`, `John Doe` */
  name1: Scalars['String'];
  /** typically the business, e.g. `Foo Corp` */
  name2?: Maybe<Scalars['String']>;
  /** Street Address, e.g. `123 Main St` */
  address1: Scalars['String'];
  /**
   * second line of address
   *
   * typically the unit or room number, e.g. `Unit 4`
   */
  address2?: Maybe<Scalars['String']>;
  /** City, e.g. `Milwaukee` */
  city: Scalars['String'];
  /** Postal Code e.g. `53212` */
  postal?: Maybe<Scalars['String']>;
  /** State / Province, e.g. `Wisconsin` */
  state: Scalars['String'];
  /** Country, e.g. `USA` */
  country: Scalars['String'];
  /** Phone */
  phone: Scalars['String'];
  /** Email */
  email: Scalars['Email'];
};

export type AddressInput = {
  /** typically the recipient, e.g. `Accts Receiving`, `John Doe` */
  name1: Scalars['String'];
  /** typically the business, e.g. `Foo Corp` */
  name2?: Maybe<Scalars['String']>;
  /** Street Address, e.g. `123 Main St` */
  address1: Scalars['String'];
  /**
   * second line of address
   *
   * typically the unit or room number, e.g. `Unit 4`
   */
  address2?: Maybe<Scalars['String']>;
  /** City, e.g. `Milwaukee` */
  city: Scalars['String'];
  /** Postal Code e.g. `53212` */
  postal?: Maybe<Scalars['String']>;
  /** State / Province, e.g. `Wisconsin` */
  state: Scalars['String'];
  /** Country, e.g. `USA` */
  country: Scalars['String'];
  /** Phone */
  phone: Scalars['String'];
  /** Email */
  email: Scalars['Email'];
};

/** A special type of user used for accessing the astronautics API */
export type ApiCredential = Node & Timestamps & {
  __typename?: 'ApiCredential';
  id: Scalars['ID'];
  clientId: Scalars['String'];
  description: Scalars['String'];
  active: Scalars['Boolean'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
};

export type AstroLoginResult = {
  __typename?: 'AstroLoginResult';
  /** `Success` when the user has been logged in */
  result?: Maybe<LoginResult>;
  /**
   * JWT token provided if login was a success.
   *
   * For app users, token will be saved as a server-only cookie and can be ignored
   *
   * For API users, send the token in the `authorization` http header to authenticate, like so:
   *
   * ```
   * authorization: Bearer {token}
   * ```
   */
  token?: Maybe<Scalars['String']>;
};

export type CreateApiCredentialResponse = {
  __typename?: 'CreateApiCredentialResponse';
  secret: Scalars['String'];
  apiCredentials: ApiCredential;
};

/** Create Device Request */
export type CreateDeviceRequest = DeviceAction & {
  __typename?: 'CreateDeviceRequest';
  newIccid?: Maybe<Scalars['ICCID']>;
  id: Scalars['ID'];
  account: Account;
  user?: Maybe<User>;
  device: Device;
  submitted: Scalars['DateTime'];
  completed?: Maybe<Scalars['DateTime']>;
  modified: Scalars['DateTime'];
  /** Raw JSON data for device for meta data props updated / set */
  deviceMetadata: Scalars['String'];
};

/** Data Plan */
export type DataPlan = Node & {
  __typename?: 'DataPlan';
  id: Scalars['ID'];
  /** Display name of the data plan */
  name: Scalars['String'];
  /** Unique name or number to represent the data plan */
  planNumber: Scalars['String'];
  /** Price Per Month in USD */
  pricePerMonth: Scalars['Float'];
  /** Inclusive data in megabytes */
  includedMb: Scalars['Float'];
  /** Price Per Year in USD */
  annualFee: Scalars['Float'];
  /** Price per megabyte after includedMb in USD */
  overagePricePerMb: Scalars['Float'];
  /** The optional accounts available for this data plan */
  accounts: Array<Account>;
  renewalTimeInMonths?: Maybe<Scalars['Int']>;
};

export type DataPlanOverride = {
  /** Data Plan Name */
  name: Scalars['String'];
  /** Data Plan Number */
  planNumber: Scalars['String'];
  /** Monthly Fee (USD) */
  monthlyFee: Scalars['Float'];
  /** Data Limit (MD) */
  dataLimit: Scalars['Float'];
  /** Overage Fee (MD) */
  overageFee: Scalars['Float'];
  /** Annual Fee (MD) */
  annualFee?: Maybe<Scalars['Float']>;
};

/** Data Plan Change */
export type DataPlanRequest = DeviceAction & {
  __typename?: 'DataPlanRequest';
  /** Desired Data Plan. When null the data plan is cleared. */
  dataPlan?: Maybe<DataPlan>;
  id: Scalars['ID'];
  account: Account;
  user?: Maybe<User>;
  device: Device;
  submitted: Scalars['DateTime'];
  completed?: Maybe<Scalars['DateTime']>;
  modified: Scalars['DateTime'];
};

export type DataUsage = {
  __typename?: 'DataUsage';
  iccid?: Maybe<Scalars['ICCID']>;
  time?: Maybe<Scalars['DateTime']>;
  bytes?: Maybe<Scalars['Float']>;
};


/** Decommission Request */
export type DecommissionRequest = DeviceAction & {
  __typename?: 'DecommissionRequest';
  id: Scalars['ID'];
  account: Account;
  device: Device;
  user?: Maybe<User>;
  submitted: Scalars['DateTime'];
  completed?: Maybe<Scalars['DateTime']>;
  modified: Scalars['DateTime'];
};

/** Device */
export type Device = Node & HasAccount & Timestamps & {
  __typename?: 'Device';
  /** UUID of the device set by application */
  id: Scalars['ID'];
  /** The account the device belongs to */
  account: Account;
  /** The ICCID of the Sim Card */
  iccid?: Maybe<Scalars['ICCID']>;
  /** The msisdn of the device (this can be thought of as a phone number, will not change with iccid) */
  msisdn?: Maybe<Scalars['String']>;
  /** the product model of the device */
  model: Product;
  /** The Astronautics serial number for this device (optional) */
  deviceSerialNumber?: Maybe<Scalars['String']>;
  /**
   * A unique serial number of aircraft or tail number
   *
   * This field must be unique within an account.
   *
   * It is used to help a user find a device
   */
  aircraftSerialNumber?: Maybe<Scalars['String']>;
  /** The name or type of aircraft the device is installed on. */
  aircraftType?: Maybe<Scalars['String']>;
  /**
   * Identifies the name of the craft’s operator, by personal name or organization/business name.
   * (e.g. `John Smith`, or `Caribbean VIP Tours`)
   */
  operatorName?: Maybe<Scalars['String']>;
  /** When the device was last activated. `null` if the device is currently inactive. */
  activated?: Maybe<Scalars['DateTime']>;
  /**
   * Status we expect the device to change to upon the completion of a succesful provision
   *
   * null if no actions are pending
   * When the device was deleted / decommissioned from the account. `null` if the device is currently tied up to an account.
   */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** True if the device has an async action pending */
  pendingStatus?: Maybe<SubscriptionStatus>;
  /** list of features enabled on the device */
  enabledFeatures: Array<ProductFeature>;
  /** The status of the device's subscription */
  subscriptionStatus: SubscriptionStatus;
  /** History of provisioning actions */
  actions: Array<DeviceAction>;
  /** data plan the device is set to, `null` when no data plan has been assigned */
  dataPlan?: Maybe<DataPlan>;
  /** A time series of a device at specified interval */
  dataUsage: TimeSeries;
  /** TODO The current usage */
  currentCycleDataUsage: Scalars['Float'];
  /** TODO: The data usage for last billing cycle */
  lastCycleDataUsage: Scalars['Float'];
  /**
   * File to upload to device to enable features
   *
   * TODO xml for now, but will be a base64 encoded binary file when released
   */
  featureFile?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  lastRenewal?: Maybe<Scalars['DateTime']>;
  nextRenewal?: Maybe<Scalars['DateTime']>;
};


/** Device */
export type DeviceDataUsageArgs = {
  start: Scalars['DateTime'];
  stop: Scalars['DateTime'];
  interval: TimeInterval;
};

/** A device action logs an action that modifies a device */
export type DeviceAction = {
  /** Unique ID of the device action */
  id: Scalars['ID'];
  /** The account the action applies to */
  account: Account;
  /**
   * The user who created the action
   *
   * null if this was done by system
   */
  user?: Maybe<User>;
  /** The device the action applies to */
  device: Device;
  /** The time the request was submitted */
  submitted: Scalars['DateTime'];
  /** The time the request succeeded or failed */
  completed?: Maybe<Scalars['DateTime']>;
  /** Last time action was modified */
  modified: Scalars['DateTime'];
};

/** A response to a device action. Either a `SuccessResponse` or `ErrorResponse` */
export type DeviceActionResponse = SuccessResponse | ErrorResponse;

export type DeviceFilterInput = {
  /** filter by subscription status */
  subscriptionStatus?: Maybe<Array<SubscriptionStatus>>;
  /** filter devices by featureNumber */
  enabledFeatures?: Maybe<Array<Scalars['String']>>;
  /** min percentage of plan used s.t. 50 = 50%, if null minDataUsage = 0 */
  minDataUsage?: Maybe<Scalars['Float']>;
  /** max percentage of plan used s.t. 50 = 50%, if null there is no upper bound */
  maxDataUsage?: Maybe<Scalars['Float']>;
  activationDate?: Maybe<TimeSpan>;
};

/**
 * Used to select a single device
 *
 * Must provide either:
 *
 * - `accountId` (if admin user)
 *
 * and one of the following:
 *
 * - `deviceId`
 * - `iccid`
 * - `deviceSerialNumber`
 * - `aircraftSerialNumber` and `aircraftType`
 */
export type DeviceLookup = {
  /** Account ID. Optional for single account users */
  accountId?: Maybe<Scalars['ID']>;
  deviceId?: Maybe<Scalars['ID']>;
  iccid?: Maybe<Scalars['ICCID']>;
  deviceSerialNumber?: Maybe<Scalars['String']>;
  /** `aircraftType` reqired if looking up by serial number */
  aircraftSerialNumber?: Maybe<Scalars['String']>;
  /** `aircraft type` required if looking up by aircraft type */
  aircraftType?: Maybe<Scalars['String']>;
};

export type DeviceResults = {
  __typename?: 'DeviceResults';
  results: Array<Device>;
  /** Total number of matching devices */
  numOfResults: Scalars['Int'];
  /** number of results skipped */
  skip: Scalars['Int'];
};

export type DeviceSort = {
  field?: Maybe<DeviceSortFields>;
  direction?: Maybe<SortDirection>;
};

export enum DeviceSortFields {
  Activated = 'Activated',
  DataUsage = 'DataUsage'
}

/** Summary of Devices in account */
export type DeviceSummary = {
  __typename?: 'DeviceSummary';
  pendingDevices: Scalars['Int'];
  activeDevices: Scalars['Int'];
  lockedDevices: Scalars['Int'];
  deactivatedDevices: Scalars['Int'];
  totalDevices: Scalars['Int'];
  totalBytes: Scalars['Float'];
  dataOverages: Scalars['Int'];
};


/** Returned when device action failed */
export type ErrorResponse = {
  __typename?: 'ErrorResponse';
  error: Array<Scalars['String']>;
};

/** Used to denote whether feature was removed or added */
export enum FeatureAction {
  /** Add a feature */
  Add = 'Add',
  /** Remove a feature */
  Remove = 'Remove'
}

/** Logs the addition or removal of an optional `DeviceFeature` */
export type FeatureRequest = DeviceAction & {
  __typename?: 'FeatureRequest';
  feature: ProductFeature;
  /** if `true` the feature has been added. if `false` the feature has been removed */
  featureAdded: Scalars['Boolean'];
  id: Scalars['ID'];
  account: Account;
  user?: Maybe<User>;
  device: Device;
  submitted: Scalars['DateTime'];
  completed?: Maybe<Scalars['DateTime']>;
  modified: Scalars['DateTime'];
};

export type GenericFees = {
  /** SIM Activation Fee (USD) */
  simActivationFee: Scalars['Float'];
  /** Feature #1 [Internet Connectivity] Fee (USD) */
  feature1: Scalars['Float'];
  /** Feature #2 [Wired to Wireless Gateway] Fee (USD) */
  feature2: Scalars['Float'];
  /** Feature #3 [FADEC Data Recording] Fee (USD) */
  feature3: Scalars['Float'];
  /** Escalation (%) */
  escalation: Scalars['Float'];
};

/** `HasAccount` means an object is associated with an account */
export type HasAccount = {
  account: Account;
};


/** Invoice */
export type Invoice = Node & HasAccount & {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  invoiceNumber: Scalars['String'];
  account: Account;
  invoiceDate: Scalars['DateTime'];
  billingStart: Scalars['DateTime'];
  billingStop: Scalars['DateTime'];
  invoiceUrl: Scalars['String'];
  published: Scalars['Boolean'];
};

export type InvoiceAdjustments = {
  /** Customer */
  account?: Maybe<Scalars['String']>;
  /** Billing Year */
  billingYear?: Maybe<Scalars['Int']>;
  /** Billing Month */
  billingMonth?: Maybe<Scalars['Int']>;
  /** Custom Invoice Name */
  invoiceName?: Maybe<Scalars['String']>;
  /** SIM Coverage Exemption List */
  simExemptions?: Maybe<Array<Scalars['ICCID']>>;
  /** Exempt All Overages */
  exemptAll: Scalars['Boolean'];
  /** Data plan specific overrides */
  dataPlanOverrides?: Maybe<Array<DataPlanOverride>>;
  /** Generic Fees */
  genericFees: GenericFees;
};

export enum LoginResult {
  /** User has been logged in */
  Success = 'Success',
  /**
   * Denotes that the password or token has expired and should be reset
   *
   * - If login is password, user should update password with `updateExpiredPassword` mutation.
   *
   * - If login is token, user should generate new token with `forgotPassword` mutation.
   */
  Expired = 'Expired',
  /** Either the account doesn't exist or the password is wrong */
  Failure = 'Failure',
  /** User reached maximum login attempts */
  Blocked = 'Blocked',
  /** User not registered */
  Unregistered = 'Unregistered',
  /**
   * Returned on password reset only
   *
   * New Password can not be same as current password
   */
  OldPassword = 'OldPassword'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Activate a SIM card on a device. This action incurs a fee.
   *
   * Auth: Account Member
   */
  activateDevice: DeviceActionResponse;
  /** Add a new data plan */
  addDataPlan: DataPlan;
  /**
   * Add a device to an account
   *
   * Auth: Account Member
   */
  addDevice: DeviceActionResponse;
  /**
   * Add feature
   *
   * Auth: Account Member
   */
  addFeature: DeviceActionResponse;
  /**
   * Add a new user to your account
   *
   * Auth: Account Admin
   */
  addUser: User;
  /**
   * Change data plan for a active or locked device
   *
   * Auth: Account Member
   */
  changeDataPlan?: Maybe<DeviceActionResponse>;
  /**
   * Assign a new SIM card to a device
   *
   * This will deactivate the existing SIM Card if it's active.
   *
   * Auth: Account Member
   */
  changeSim: DeviceActionResponse;
  /** apply email change via token. If user is logged in the token cookie will be reset. */
  confirmEmail: Scalars['Boolean'];
  /**
   * Create a new account in the portal
   *
   * Auth: Astronautics Only
   */
  createAccount: Account;
  /**
   * Create a new API credential
   *
   * Token will be returned in response. For security, we do not keep a copy of the orginial token,
   * so it must be saved.
   *
   * If secret is forgotten, use `revokeApiCredentials` and create a new credential.
   *
   * Auth: Account Admin
   */
  createApiCredential: CreateApiCredentialResponse;
  /**
   * Create a new product in the portal
   *
   * Auth: Astronautics Only
   */
  createProduct: Product;
  /**
   * Create a new product feature in the portal
   *
   * Auth: Astronautics Only
   */
  createProductFeature: ProductFeature;
  /** Deactivate a data plan */
  deactivateDataPlan?: Maybe<DataPlan>;
  /**
   * Deactivate the device
   *
   * Auth: Account Member
   */
  deactivateDevice: DeviceActionResponse;
  /**
   * Decommission Device
   *
   * This will delete / decommission device from the account. If the device is activated / locked, it will be deactivated.
   *
   * Auth: Account Member
   */
  decommissionDevice: DeviceActionResponse;
  /** Permanently delete a data plan */
  deleteDataPlan?: Maybe<Array<Maybe<DataPlan>>>;
  /** starts lost password flow. Returns true if email was sent to the user */
  forgotPassword?: Maybe<Scalars['Boolean']>;
  generateInvoice: Scalars['String'];
  /**
   * Lock Device
   *
   * Auth: Account Member
   */
  lockDevice: DeviceActionResponse;
  /** login using username and password */
  login?: Maybe<AstroLoginResult>;
  logout?: Maybe<Scalars['Boolean']>;
  /**
   * Refresh Device
   *
   * This will ensure device state matches state on network
   *
   * Auth: Astro Admin
   */
  refreshDevice: DeviceActionResponse;
  /** refresh the token sent in the header or cookie */
  refreshToken?: Maybe<AstroLoginResult>;
  /** register your account using the token sent in email */
  registerAccount?: Maybe<AstroLoginResult>;
  /**
   * Remove feature
   *
   * Auth: Account Member
   */
  removeFeature: DeviceActionResponse;
  /**
   * Remove an existing user from an account
   *
   * Auth: Account Admin. Scope: UI only
   */
  removeUser: Account;
  /**
   * Request SIM Card
   *
   * Auth: Account Member
   */
  replaceSim?: Maybe<DeviceActionResponse>;
  /** Request Access */
  requestAccess?: Maybe<Scalars['Boolean']>;
  /** reset password using the reset password flow */
  resetPassword?: Maybe<AstroLoginResult>;
  /**
   * Revoke API credentials
   *
   * Once revoked, the credentials can not be used again.
   *
   * Auth: Account Admin
   */
  revokeApiCredential: Scalars['Boolean'];
  /** get a token to authenticate API calls. Tokens last 24hrs */
  token?: Maybe<AstroLoginResult>;
  /**
   * Unlock Device
   *
   * Auth: Account Member
   */
  unlockDevice: DeviceActionResponse;
  /** Updates account name and primaryContact */
  updateAccount: Account;
  /** Update a data plan */
  updateDataPlan: DataPlan;
  /**
   * Update Device Metadata
   *
   * Auth: Account Member
   */
  updateDevice?: Maybe<DeviceActionResponse>;
  /** Start update email flow. Return false if no email was sent to new address. */
  updateEmail: Scalars['Boolean'];
  /** update an expired password */
  updateExpiredPassword?: Maybe<AstroLoginResult>;
  /** update password */
  updatePassword?: Maybe<Scalars['Boolean']>;
  /**
   * Update a new product in the portal
   *
   * Auth: Astronautics Only
   */
  updateProduct: Product;
  /** Updates account name and primaryContact */
  updateProductFeature: ProductFeature;
  /** update user */
  updateUser?: Maybe<User>;
};


export type MutationActivateDeviceArgs = {
  device: DeviceLookup;
  planNumber: Scalars['String'];
};


export type MutationAddDataPlanArgs = {
  name: Scalars['String'];
  planNumber: Scalars['String'];
  pricePerMonth: Scalars['Float'];
  includedMb: Scalars['Float'];
  overagePricePerMb: Scalars['Float'];
  renewalTimeInMonths?: Maybe<Scalars['Int']>;
  annualFee?: Maybe<Scalars['Float']>;
  accounts?: Maybe<Array<Maybe<AccountInput>>>;
};


export type MutationAddDeviceArgs = {
  accountId?: Maybe<Scalars['ID']>;
  iccid: Scalars['ICCID'];
  deviceModelNumber: Scalars['String'];
  deviceSerialNumber?: Maybe<Scalars['String']>;
  aircraftSerialNumber?: Maybe<Scalars['String']>;
  aircraftType?: Maybe<Scalars['String']>;
  operatorName?: Maybe<Scalars['String']>;
  dataPlanNumber?: Maybe<Scalars['String']>;
  optionalFeatureNumbers?: Maybe<Array<Scalars['String']>>;
  activate?: Maybe<Scalars['Boolean']>;
};


export type MutationAddFeatureArgs = {
  device: DeviceLookup;
  featureNumber: Scalars['String'];
};


export type MutationAddUserArgs = {
  accountId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  email: Scalars['Email'];
  accountAdmin?: Maybe<Scalars['Boolean']>;
};


export type MutationChangeDataPlanArgs = {
  device: DeviceLookup;
  planNumber: Scalars['String'];
};


export type MutationChangeSimArgs = {
  device: DeviceLookup;
  newSim: Scalars['ICCID'];
};


export type MutationConfirmEmailArgs = {
  token: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountOwnerName: Scalars['String'];
  accountOwnerEmail: Scalars['Email'];
};


export type MutationCreateApiCredentialArgs = {
  accountId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationCreateProductArgs = {
  name: Scalars['String'];
  modelNumber: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<ProductFeatureInput>>>;
};


export type MutationCreateProductFeatureArgs = {
  featureNumber: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};


export type MutationDeactivateDataPlanArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateDeviceArgs = {
  device: DeviceLookup;
};


export type MutationDecommissionDeviceArgs = {
  device: DeviceLookup;
};


export type MutationDeleteDataPlanArgs = {
  id: Scalars['ID'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['Email'];
};


export type MutationGenerateInvoiceArgs = {
  invoiceAdjustments?: Maybe<InvoiceAdjustments>;
};


export type MutationLockDeviceArgs = {
  device: DeviceLookup;
};


export type MutationLoginArgs = {
  email: Scalars['Email'];
  password: Scalars['String'];
};


export type MutationRefreshDeviceArgs = {
  device: DeviceLookup;
};


export type MutationRegisterAccountArgs = {
  token: Scalars['String'];
  email: Scalars['Email'];
  newPassword: Scalars['Password'];
  name: Scalars['String'];
};


export type MutationRemoveFeatureArgs = {
  device: DeviceLookup;
  featureNumber: Scalars['String'];
};


export type MutationRemoveUserArgs = {
  accountId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationReplaceSimArgs = {
  device: DeviceLookup;
  address: AddressInput;
  reason: Scalars['String'];
};


export type MutationRequestAccessArgs = {
  email: Scalars['Email'];
  phone: Scalars['String'];
  organization: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  email: Scalars['Email'];
  token: Scalars['String'];
  newPassword: Scalars['Password'];
};


export type MutationRevokeApiCredentialArgs = {
  accountId?: Maybe<Scalars['ID']>;
  apiCredentialId: Scalars['ID'];
};


export type MutationTokenArgs = {
  clientId: Scalars['String'];
  secret: Scalars['String'];
};


export type MutationUnlockDeviceArgs = {
  device: DeviceLookup;
};


export type MutationUpdateAccountArgs = {
  accountId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  primaryContact?: Maybe<AddressInput>;
};


export type MutationUpdateDataPlanArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  planNumber?: Maybe<Scalars['String']>;
  pricePerMonth?: Maybe<Scalars['Float']>;
  includedMb?: Maybe<Scalars['Float']>;
  overagePricePerMb?: Maybe<Scalars['Float']>;
  renewalTimeInMonths?: Maybe<Scalars['Int']>;
  annualFee?: Maybe<Scalars['Float']>;
  accounts?: Maybe<Array<Maybe<AccountInput>>>;
};


export type MutationUpdateDeviceArgs = {
  device: DeviceLookup;
  deviceSerialNumber?: Maybe<Scalars['String']>;
  deviceModelNumber?: Maybe<Scalars['String']>;
  aircraftSerialNumber?: Maybe<Scalars['String']>;
  aircraftType?: Maybe<Scalars['String']>;
  operatorName?: Maybe<Scalars['String']>;
  nextRenewalMonth?: Maybe<Scalars['Int']>;
  nextRenewalYear?: Maybe<Scalars['Int']>;
};


export type MutationUpdateEmailArgs = {
  newEmail: Scalars['Email'];
};


export type MutationUpdateExpiredPasswordArgs = {
  email: Scalars['Email'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['Password'];
};


export type MutationUpdatePasswordArgs = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['Password'];
};


export type MutationUpdateProductArgs = {
  productId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<ProductFeatureInput>>>;
};


export type MutationUpdateProductFeatureArgs = {
  productFeatureId: Scalars['ID'];
  featureNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  name?: Maybe<Scalars['String']>;
};

/**
 * `Node` is used to mark persisted data.
 *
 * The `id` property on node may be used in the future to control client side cache invalidation.
 */
export type Node = {
  /** id is a randomly generated ID unique to the data management portal */
  id: Scalars['ID'];
};


/** An Astronautics Product */
export type Product = Node & {
  __typename?: 'Product';
  id: Scalars['ID'];
  /** The human readable product name */
  name: Scalars['String'];
  /** The model number is the SKU or unique id to represent a product */
  modelNumber: Scalars['String'];
  /** A paragraph short describing the product */
  description?: Maybe<Scalars['String']>;
  /** The optional features available for this product */
  features: Array<ProductFeature>;
};

/** Optional Features that can be enabled on a product */
export type ProductFeature = Node & {
  __typename?: 'ProductFeature';
  id: Scalars['ID'];
  /** The unique id that astronautics uses to identify this feature by */
  featureNumber: Scalars['String'];
  /**
   * The name of the feature
   *
   * e.g. Cabin WiFi
   */
  name: Scalars['String'];
  /** A short paragraph describing the feature */
  description?: Maybe<Scalars['String']>;
  /** Products where this feature is available */
  products: Array<Product>;
};

export type ProductFeatureInput = {
  /** UUID */
  id: Scalars['ID'];
  /** The unique id that astronautics uses to identify this feature by */
  featureNumber?: Maybe<Scalars['String']>;
  /**
   * The name of the feature
   *
   * e.g. Cabin WiFi
   */
  name?: Maybe<Scalars['String']>;
  /** A short paragraph describing the feature */
  description?: Maybe<Scalars['String']>;
};

/** Types of actions that can be performed on Sim Cards */
export enum ProvisionAction {
  /** Activate the Device */
  Activate = 'Activate',
  /** Deactivate the Device */
  Deactivate = 'Deactivate',
  /** Lock the Device */
  Lock = 'Lock',
  /** Unlock the Device */
  Unlock = 'Unlock',
  /** Change the ICCID */
  ChangeIccid = 'ChangeIccid'
}

/** Provision Request is used to log SIM card provisioning */
export type ProvisionRequest = DeviceAction & {
  __typename?: 'ProvisionRequest';
  /** ICCID this action applies to */
  iccid?: Maybe<Scalars['ICCID']>;
  /** the provisioning action taking place */
  action: ProvisionAction;
  /** iccid the device was set to when provision action is `ChangeICCID` */
  newIccid?: Maybe<Scalars['ICCID']>;
  /** data plan */
  dataPlan?: Maybe<DataPlan>;
  /**
   * status of the action, `Pending`, `Complete`, or `Error`
   *
   * it will take about a minute for an action to complete
   */
  status: ProvisionStatus;
  /** message about why provisioning request failed */
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  account: Account;
  user?: Maybe<User>;
  device: Device;
  submitted: Scalars['DateTime'];
  completed?: Maybe<Scalars['DateTime']>;
  modified: Scalars['DateTime'];
};

/** Status of a provision request. i.e. if it's `Pending` or `Completed` */
export enum ProvisionStatus {
  /** Provision request is in progress. It has yet to succeed or fail. */
  Pending = 'Pending',
  /** The request action has completed */
  Complete = 'Complete',
  /** The requested action has failed */
  Failed = 'Failed'
}

export type Query = {
  __typename?: 'Query';
  /** get an account by account id */
  account: Account;
  /** get an account by account number */
  accountByAccountNumber: Account;
  /**
   * get all accounts managed by the DMP
   *
   * Auth: Astro Admins Only
   */
  accounts: Array<Account>;
  /** A list of all products from Astronautics */
  catalog: Array<Product>;
  /** Data Plans by ID */
  dataPlanById: DataPlan;
  /** Data Plans */
  dataPlans: Array<DataPlan>;
  /** Data Plans by Account Number */
  dataPlansByAccountNumber: Array<DataPlan>;
  /** returns the logged in user, or null if user is not logged in */
  me?: Maybe<User>;
  /** A list of all product features from Astronautics */
  productFeatures: Array<ProductFeature>;
  /** probe the token for registration / password resets screens */
  tokenInfo?: Maybe<UserTokenInfo>;
  /** When `Available`, the Sim Card can be used in a new device or to replace a sim card. */
  verifyIccid: SimCardAvailabilityStatus;
};


export type QueryAccountArgs = {
  accountId?: Maybe<Scalars['ID']>;
};


export type QueryAccountByAccountNumberArgs = {
  accountNumber: Scalars['String'];
};


export type QueryDataPlanByIdArgs = {
  dataPlanId?: Maybe<Scalars['ID']>;
};


export type QueryDataPlansByAccountNumberArgs = {
  accountNumber?: Maybe<Scalars['String']>;
};


export type QueryTokenInfoArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type QueryVerifyIccidArgs = {
  iccid: Scalars['ICCID'];
};

/** Sim Replacement Request */
export type ReplaceSimRequest = DeviceAction & {
  __typename?: 'ReplaceSimRequest';
  /** The address the SIM should be sent to */
  shippingAddress?: Maybe<Address>;
  /** The reason the SIM is being replaced */
  message?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  account: Account;
  user?: Maybe<User>;
  device: Device;
  submitted: Scalars['DateTime'];
  completed?: Maybe<Scalars['DateTime']>;
  modified: Scalars['DateTime'];
};

export enum SimCardAvailabilityStatus {
  /** Sim Card can be added to a new device or swapped to from existing device */
  Available = 'Available',
  /** Sim Card has been claimed by another device or has been used. */
  Claimed = 'Claimed',
  /** Sim Card is not found in our records */
  NotFound = 'NotFound',
  /** Sim Card is reserved for use on test enviroment */
  TestIccid = 'TestIccid',
  /** Sim Card is managed by something other than the data management portal */
  Used = 'Used'
}

export enum SortDirection {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

/** Represents the Status of Device's Subscription */
export enum SubscriptionStatus {
  /** `Pending` means the device has never been activated */
  Pending = 'Pending',
  /** `Activated` means the device is active */
  Activated = 'Activated',
  /** `Deactivated` means the device was once active but is no longer */
  Deactivated = 'Deactivated',
  /**
   * `Locked` means the device is currently locked and will not be able to connect to the network.
   * Device must be unlocked to resume data connectivity.
   */
  Locked = 'Locked'
}

/** Returned when device action worked */
export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  /** The Device */
  device: Device;
  /** Resulting Requests */
  response: Array<DeviceAction>;
};

/** Interval of time used to fetch the data usage */
export enum TimeInterval {
  HalfHour = 'HalfHour',
  Hour = 'Hour',
  Day = 'Day',
  Month = 'Month'
}

/** Time Series is used to summarize the data usage of an account or device */
export type TimeSeries = {
  __typename?: 'TimeSeries';
  /** Time of first data point in the series */
  start: Scalars['DateTime'];
  /** Time of last data point in series */
  stop: Scalars['DateTime'];
  /** Unit of Time each element of the Series Corresponds to */
  interval: TimeInterval;
  /**
   * Series is an array of numbers that represent the amount of bytes used, such that:
   *
   * 1. the start time `series[i]` corresponds to is equal to `start + interval * i`
   * 2. the stop time of `series[i]` is `start + interval * i + interval`
   * 3. the value is the bytes of data consumed from the start time up until the stop time
   */
  series: Array<Scalars['Float']>;
  /** The total bytes used in wholse series */
  sum: Scalars['Float'];
};

export type TimeSpan = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

/** Defines the set of timestamps that can be used in the API (created date / last modified date) */
export type Timestamps = {
  /** When the object was first added */
  created: Scalars['DateTime'];
  /** When the object was created */
  modified: Scalars['DateTime'];
};

/** UpdateDevice Request */
export type UpdateDeviceRequest = DeviceAction & {
  __typename?: 'UpdateDeviceRequest';
  id: Scalars['ID'];
  account: Account;
  device: Device;
  user?: Maybe<User>;
  submitted: Scalars['DateTime'];
  completed?: Maybe<Scalars['DateTime']>;
  modified: Scalars['DateTime'];
  /** Raw JSON data for device for meta data props updated / set */
  deviceMetadata: Scalars['String'];
};

/** A user is a member of an account(s) that can login to a portal. */
export type User = Node & Timestamps & {
  __typename?: 'User';
  id: Scalars['ID'];
  /** Name of user */
  name: Scalars['String'];
  /** Email of user, this is used to login and must be unique. */
  email: Scalars['Email'];
  /**
   * A list of accounts the user has access to.
   *
   * When account is empty, the user no longer has the ability to login.
   */
  accounts: Array<UserAccountEdge>;
  /** true if this user is an astro admin...someone who can manage all accounts */
  isAstroAdmin: Scalars['Boolean'];
  /**
   * `false` if the user has been added to an account but has not yet registered.
   * `true` otherwise
   */
  isRegistered: Scalars['Boolean'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
};

/** Represents the membership of a `User` in an `Account` */
export type UserAccountEdge = Node & {
  __typename?: 'UserAccountEdge';
  id: Scalars['ID'];
  /** The level of access a user has within an account */
  role: AccountRole;
  account: Account;
  user: User;
};

/** Basic information about a registration / password reset token */
export type UserTokenInfo = {
  __typename?: 'UserTokenInfo';
  tokenIsExpired: Scalars['Boolean'];
  isRegistered: Scalars['Boolean'];
};

export type DeviceActionMutableFieldsFragment = (
  { __typename?: 'Device' }
  & Pick<Device, 'id' | 'iccid' | 'operatorName' | 'deviceSerialNumber' | 'aircraftSerialNumber' | 'aircraftType' | 'subscriptionStatus' | 'pendingStatus' | 'deletedAt' | 'lastRenewal' | 'nextRenewal'>
  & { model: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'modelNumber' | 'description'>
  ), dataPlan?: Maybe<(
    { __typename?: 'DataPlan' }
    & Pick<DataPlan, 'id' | 'includedMb' | 'name' | 'planNumber'>
  )>, enabledFeatures: Array<(
    { __typename?: 'ProductFeature' }
    & Pick<ProductFeature, 'id' | 'name' | 'featureNumber' | 'description'>
  )>, account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { deviceSummary: (
      { __typename?: 'DeviceSummary' }
      & Pick<DeviceSummary, 'pendingDevices' | 'activeDevices' | 'lockedDevices' | 'deactivatedDevices' | 'totalDevices' | 'totalBytes' | 'dataOverages'>
    ) }
  ) }
);

type DeviceActionResponse_SuccessResponse_Fragment = (
  { __typename?: 'SuccessResponse' }
  & { device: (
    { __typename?: 'Device' }
    & DeviceActionMutableFieldsFragment
  ), response: Array<(
    { __typename?: 'CreateDeviceRequest' }
    & DeviceActions_CreateDeviceRequest_Fragment
  ) | (
    { __typename?: 'DataPlanRequest' }
    & DeviceActions_DataPlanRequest_Fragment
  ) | (
    { __typename?: 'DecommissionRequest' }
    & DeviceActions_DecommissionRequest_Fragment
  ) | (
    { __typename?: 'FeatureRequest' }
    & DeviceActions_FeatureRequest_Fragment
  ) | (
    { __typename?: 'ProvisionRequest' }
    & DeviceActions_ProvisionRequest_Fragment
  ) | (
    { __typename?: 'ReplaceSimRequest' }
    & DeviceActions_ReplaceSimRequest_Fragment
  ) | (
    { __typename?: 'UpdateDeviceRequest' }
    & DeviceActions_UpdateDeviceRequest_Fragment
  )> }
);

type DeviceActionResponse_ErrorResponse_Fragment = (
  { __typename?: 'ErrorResponse' }
  & Pick<ErrorResponse, 'error'>
);

export type DeviceActionResponseFragment = DeviceActionResponse_SuccessResponse_Fragment | DeviceActionResponse_ErrorResponse_Fragment;

type DeviceActions_CreateDeviceRequest_Fragment = (
  { __typename: 'CreateDeviceRequest' }
  & Pick<CreateDeviceRequest, 'id' | 'submitted' | 'completed'>
);

type DeviceActions_DataPlanRequest_Fragment = (
  { __typename: 'DataPlanRequest' }
  & Pick<DataPlanRequest, 'id' | 'submitted' | 'completed'>
  & { dataPlan?: Maybe<(
    { __typename?: 'DataPlan' }
    & Pick<DataPlan, 'id' | 'name' | 'planNumber' | 'includedMb'>
  )> }
);

type DeviceActions_DecommissionRequest_Fragment = (
  { __typename: 'DecommissionRequest' }
  & Pick<DecommissionRequest, 'id' | 'submitted' | 'completed'>
);

type DeviceActions_FeatureRequest_Fragment = (
  { __typename: 'FeatureRequest' }
  & Pick<FeatureRequest, 'featureAdded' | 'id' | 'submitted' | 'completed'>
  & { feature: (
    { __typename?: 'ProductFeature' }
    & Pick<ProductFeature, 'id' | 'featureNumber' | 'name' | 'description'>
  ) }
);

type DeviceActions_ProvisionRequest_Fragment = (
  { __typename: 'ProvisionRequest' }
  & Pick<ProvisionRequest, 'action' | 'status' | 'errorMessage' | 'id' | 'submitted' | 'completed'>
);

type DeviceActions_ReplaceSimRequest_Fragment = (
  { __typename: 'ReplaceSimRequest' }
  & Pick<ReplaceSimRequest, 'message' | 'id' | 'submitted' | 'completed'>
);

type DeviceActions_UpdateDeviceRequest_Fragment = (
  { __typename: 'UpdateDeviceRequest' }
  & Pick<UpdateDeviceRequest, 'id' | 'submitted' | 'completed'>
);

export type DeviceActionsFragment = DeviceActions_CreateDeviceRequest_Fragment | DeviceActions_DataPlanRequest_Fragment | DeviceActions_DecommissionRequest_Fragment | DeviceActions_FeatureRequest_Fragment | DeviceActions_ProvisionRequest_Fragment | DeviceActions_ReplaceSimRequest_Fragment | DeviceActions_UpdateDeviceRequest_Fragment;

export type DeviceDetailsFragment = (
  { __typename?: 'Device' }
  & Pick<Device, 'id' | 'pendingStatus' | 'iccid' | 'currentCycleDataUsage' | 'deviceSerialNumber' | 'subscriptionStatus' | 'aircraftSerialNumber' | 'aircraftType' | 'operatorName' | 'deletedAt' | 'created' | 'modified' | 'lastRenewal' | 'nextRenewal'>
  & { dataPlan?: Maybe<(
    { __typename?: 'DataPlan' }
    & Pick<DataPlan, 'id' | 'name' | 'planNumber' | 'includedMb'>
  )>, model: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'modelNumber' | 'description'>
    & { features: Array<(
      { __typename?: 'ProductFeature' }
      & Pick<ProductFeature, 'id' | 'featureNumber' | 'name' | 'description'>
    )> }
  ), enabledFeatures: Array<(
    { __typename?: 'ProductFeature' }
    & Pick<ProductFeature, 'id' | 'featureNumber' | 'name' | 'description'>
  )> }
);

export type ActivateDeviceMutationVariables = Exact<{
  device: DeviceLookup;
  planNumber: Scalars['String'];
}>;


export type ActivateDeviceMutation = (
  { __typename?: 'Mutation' }
  & { action: (
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  ) }
);

export type AddAccountMutationVariables = Exact<{
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountOwnerName: Scalars['String'];
  accountOwnerEmail: Scalars['Email'];
}>;


export type AddAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name' | 'accountNumber'>
  ) }
);

export type AddAccountUserMutationVariables = Exact<{
  accountId: Scalars['ID'];
  email: Scalars['Email'];
  name: Scalars['String'];
  accountAdmin: Scalars['Boolean'];
}>;


export type AddAccountUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email'>
  ) }
);

export type AddDataPlanMutationVariables = Exact<{
  name: Scalars['String'];
  planNumber: Scalars['String'];
  pricePerMonth: Scalars['Float'];
  includedMb: Scalars['Float'];
  overagePricePerMb: Scalars['Float'];
  annualFee?: Maybe<Scalars['Float']>;
  renewalTimeInMonths?: Maybe<Scalars['Int']>;
  accounts?: Maybe<Array<Maybe<AccountInput>>>;
}>;


export type AddDataPlanMutation = (
  { __typename?: 'Mutation' }
  & { addDataPlan: (
    { __typename?: 'DataPlan' }
    & Pick<DataPlan, 'id' | 'name' | 'planNumber' | 'pricePerMonth' | 'includedMb' | 'overagePricePerMb' | 'annualFee' | 'renewalTimeInMonths'>
    & { accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'accountNumber' | 'name'>
    )> }
  ) }
);

export type AddDeviceMutationVariables = Exact<{
  accountId: Scalars['ID'];
  iccid: Scalars['ICCID'];
  activate: Scalars['Boolean'];
  deviceModelNumber: Scalars['String'];
  deviceSerialNumber?: Maybe<Scalars['String']>;
  aircraftSerialNumber?: Maybe<Scalars['String']>;
  aircraftType?: Maybe<Scalars['String']>;
  operatorName?: Maybe<Scalars['String']>;
  dataPlanNumber?: Maybe<Scalars['String']>;
  optionalFeatureNumbers?: Maybe<Array<Scalars['String']>>;
}>;


export type AddDeviceMutation = (
  { __typename?: 'Mutation' }
  & { action: (
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  ) }
);

export type AddFeatureMutationVariables = Exact<{
  accountId: Scalars['ID'];
  deviceId: Scalars['ID'];
  featureNumber: Scalars['String'];
}>;


export type AddFeatureMutation = (
  { __typename?: 'Mutation' }
  & { action: (
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  ) }
);

export type ChangeDataPlanMutationVariables = Exact<{
  device: DeviceLookup;
  planNumber: Scalars['String'];
}>;


export type ChangeDataPlanMutation = (
  { __typename?: 'Mutation' }
  & { action?: Maybe<(
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  )> }
);

export type ChangeNameMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type ChangeNameMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);

export type ChangeSimMutationVariables = Exact<{
  device: DeviceLookup;
  newSim: Scalars['ICCID'];
}>;


export type ChangeSimMutation = (
  { __typename?: 'Mutation' }
  & { action: (
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  ) }
);

export type ConfirmEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmEmail'>
);

export type CreateApiCredentialsMutationVariables = Exact<{
  accountId: Scalars['ID'];
  description: Scalars['String'];
}>;


export type CreateApiCredentialsMutation = (
  { __typename?: 'Mutation' }
  & { createApiCredential: (
    { __typename?: 'CreateApiCredentialResponse' }
    & Pick<CreateApiCredentialResponse, 'secret'>
    & { apiCredentials: (
      { __typename?: 'ApiCredential' }
      & Pick<ApiCredential, 'id' | 'clientId'>
    ) }
  ) }
);

export type CreateProductMutationVariables = Exact<{
  name: Scalars['String'];
  modelNumber: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<ProductFeatureInput>>>;
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'name' | 'modelNumber' | 'description'>
    & { features: Array<(
      { __typename?: 'ProductFeature' }
      & Pick<ProductFeature, 'id' | 'name' | 'description'>
    )> }
  ) }
);

export type DeactivateDeviceMutationVariables = Exact<{
  device: DeviceLookup;
}>;


export type DeactivateDeviceMutation = (
  { __typename?: 'Mutation' }
  & { action: (
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  ) }
);

export type DecommissionDeviceMutationVariables = Exact<{
  device: DeviceLookup;
}>;


export type DecommissionDeviceMutation = (
  { __typename?: 'Mutation' }
  & { action: (
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  ) }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['Email'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type GenerateInvoiceMutationVariables = Exact<{
  invoiceAdjustments?: Maybe<InvoiceAdjustments>;
}>;


export type GenerateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateInvoice'>
);

export type LockDeviceMutationVariables = Exact<{
  device: DeviceLookup;
}>;


export type LockDeviceMutation = (
  { __typename?: 'Mutation' }
  & { action: (
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['Email'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'AstroLoginResult' }
    & Pick<AstroLoginResult, 'result'>
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type RefreshDeviceMutationVariables = Exact<{
  device: DeviceLookup;
}>;


export type RefreshDeviceMutation = (
  { __typename?: 'Mutation' }
  & { action: (
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  ) }
);

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = (
  { __typename: 'Mutation' }
  & { refreshToken?: Maybe<(
    { __typename?: 'AstroLoginResult' }
    & Pick<AstroLoginResult, 'result' | 'token'>
  )> }
);

export type RegisterAccountMutationVariables = Exact<{
  token: Scalars['String'];
  email: Scalars['Email'];
  newPassword: Scalars['Password'];
  name: Scalars['String'];
}>;


export type RegisterAccountMutation = (
  { __typename?: 'Mutation' }
  & { registerAccount?: Maybe<(
    { __typename?: 'AstroLoginResult' }
    & Pick<AstroLoginResult, 'result'>
  )> }
);

export type RemoveAccountUserMutationVariables = Exact<{
  accountId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type RemoveAccountUserMutation = (
  { __typename?: 'Mutation' }
  & { removeUser: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { users: Array<(
      { __typename?: 'UserAccountEdge' }
      & Pick<UserAccountEdge, 'id' | 'role'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'email'>
      ) }
    )> }
  ) }
);

export type ReplaceSimRequestMutationVariables = Exact<{
  device: DeviceLookup;
  address: AddressInput;
  reason: Scalars['String'];
}>;


export type ReplaceSimRequestMutation = (
  { __typename?: 'Mutation' }
  & { action?: Maybe<(
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  )> }
);

export type RequestAccessMutationVariables = Exact<{
  email: Scalars['Email'];
  phone: Scalars['String'];
  organization: Scalars['String'];
  message?: Maybe<Scalars['String']>;
}>;


export type RequestAccessMutation = (
  { __typename?: 'Mutation' }
  & { action: Mutation['requestAccess'] }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['Email'];
  token: Scalars['String'];
  newPassword: Scalars['Password'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'AstroLoginResult' }
    & Pick<AstroLoginResult, 'result'>
  )> }
);

export type RevokeApiCredentialMutationVariables = Exact<{
  accountId: Scalars['ID'];
  apiCredentialId: Scalars['ID'];
}>;


export type RevokeApiCredentialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeApiCredential'>
);

export type UnlockDeviceMutationVariables = Exact<{
  device: DeviceLookup;
}>;


export type UnlockDeviceMutation = (
  { __typename?: 'Mutation' }
  & { action: (
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  ) }
);

export type UpdateAccountMutationVariables = Exact<{
  accountId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  primaryContact?: Maybe<AddressInput>;
}>;


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name'>
    & { primaryContact?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'name1' | 'name2' | 'address1' | 'address2' | 'city' | 'postal' | 'state' | 'country' | 'phone' | 'email'>
    )> }
  ) }
);

export type UpdateDataPlanMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  planNumber: Scalars['String'];
  pricePerMonth: Scalars['Float'];
  includedMb: Scalars['Float'];
  overagePricePerMb: Scalars['Float'];
  annualFee?: Maybe<Scalars['Float']>;
  renewalTimeInMonths?: Maybe<Scalars['Int']>;
  accounts?: Maybe<Array<Maybe<AccountInput>>>;
}>;


export type UpdateDataPlanMutation = (
  { __typename?: 'Mutation' }
  & { updateDataPlan: (
    { __typename?: 'DataPlan' }
    & Pick<DataPlan, 'id' | 'name' | 'planNumber' | 'pricePerMonth' | 'includedMb' | 'overagePricePerMb' | 'annualFee' | 'renewalTimeInMonths'>
    & { accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'accountNumber' | 'name'>
    )> }
  ) }
);

export type UpdateDeviceMutationVariables = Exact<{
  device: DeviceLookup;
  deviceSerialNumber?: Maybe<Scalars['String']>;
  deviceModelNumber?: Maybe<Scalars['String']>;
  aircraftSerialNumber?: Maybe<Scalars['String']>;
  operatorName?: Maybe<Scalars['String']>;
  aircraftType?: Maybe<Scalars['String']>;
  nextRenewalYear?: Maybe<Scalars['Int']>;
  nextRenewalMonth?: Maybe<Scalars['Int']>;
}>;


export type UpdateDeviceMutation = (
  { __typename?: 'Mutation' }
  & { action?: Maybe<(
    { __typename?: 'SuccessResponse' }
    & DeviceActionResponse_SuccessResponse_Fragment
  ) | (
    { __typename?: 'ErrorResponse' }
    & DeviceActionResponse_ErrorResponse_Fragment
  )> }
);

export type UpdateEmailMutationVariables = Exact<{
  newEmail: Scalars['Email'];
}>;


export type UpdateEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEmail'>
);

export type UpdateExpiredPasswordMutationVariables = Exact<{
  email: Scalars['Email'];
  oldPassword: Scalars['String'];
  newPassword: Scalars['Password'];
}>;


export type UpdateExpiredPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateExpiredPassword?: Maybe<(
    { __typename?: 'AstroLoginResult' }
    & Pick<AstroLoginResult, 'result'>
  )> }
);

export type UpdatePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['Password'];
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePassword'>
);

export type UpdateProductMutationVariables = Exact<{
  productId: Scalars['ID'];
  name: Scalars['String'];
  modelNumber: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<ProductFeatureInput>>>;
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'name' | 'modelNumber' | 'description'>
    & { features: Array<(
      { __typename?: 'ProductFeature' }
      & Pick<ProductFeature, 'id' | 'name' | 'description'>
    )> }
  ) }
);

export type AccountDetailsQueryVariables = Exact<{
  accountId?: Maybe<Scalars['ID']>;
}>;


export type AccountDetailsQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'accountNumber' | 'name'>
    & { primaryContact?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'name1' | 'name2' | 'address1' | 'address2' | 'city' | 'postal' | 'state' | 'country' | 'phone' | 'email'>
    )> }
  ) }
);

export type AdminAccountListQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminAccountListQuery = (
  { __typename?: 'Query' }
  & { accounts: Array<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name' | 'accountNumber' | 'isDeactivated'>
  )> }
);

export type DataPlansByAccountNumberQueryVariables = Exact<{
  accountNumber: Scalars['String'];
}>;


export type DataPlansByAccountNumberQuery = (
  { __typename?: 'Query' }
  & { dataPlansByAccountNumber: Array<(
    { __typename?: 'DataPlan' }
    & Pick<DataPlan, 'id' | 'name' | 'planNumber' | 'pricePerMonth' | 'includedMb' | 'annualFee' | 'overagePricePerMb'>
  )> }
);

export type DeviceActivationNeededQueryVariables = Exact<{
  accountId?: Maybe<Scalars['ID']>;
}>;


export type DeviceActivationNeededQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { devices: (
      { __typename?: 'DeviceResults' }
      & Pick<DeviceResults, 'numOfResults'>
      & { results: Array<(
        { __typename?: 'Device' }
        & Pick<Device, 'id' | 'iccid' | 'subscriptionStatus'>
        & { dataPlan?: Maybe<(
          { __typename?: 'DataPlan' }
          & Pick<DataPlan, 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type DeviceDataUsageQueryVariables = Exact<{
  accountId: Scalars['ID'];
  deviceId: Scalars['ID'];
  start: Scalars['DateTime'];
  stop: Scalars['DateTime'];
  interval: TimeInterval;
}>;


export type DeviceDataUsageQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { device?: Maybe<(
      { __typename?: 'Device' }
      & Pick<Device, 'id'>
      & { dataUsage: (
        { __typename?: 'TimeSeries' }
        & Pick<TimeSeries, 'start' | 'stop' | 'interval' | 'series' | 'sum'>
      ) }
    )> }
  ) }
);

export type DeviceDetailsQueryVariables = Exact<{
  accountId: Scalars['ID'];
  deviceId: Scalars['ID'];
}>;


export type DeviceDetailsQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { device?: Maybe<(
      { __typename?: 'Device' }
      & DeviceDetailsFragment
    )> }
  ) }
);

export type DeviceFeatureFileQueryVariables = Exact<{
  accountId: Scalars['ID'];
  deviceId: Scalars['ID'];
}>;


export type DeviceFeatureFileQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { device?: Maybe<(
      { __typename?: 'Device' }
      & Pick<Device, 'id' | 'featureFile'>
    )> }
  ) }
);

export type DeviceListQueryVariables = Exact<{
  accountId: Scalars['ID'];
  sd?: Maybe<SortDirection>;
  sf?: Maybe<DeviceSortFields>;
  ss?: Maybe<Array<SubscriptionStatus>>;
  feat?: Maybe<Array<Scalars['String']>>;
  dumax?: Maybe<Scalars['Float']>;
  dumin?: Maybe<Scalars['Float']>;
  actStart?: Maybe<Scalars['DateTime']>;
  actEnd?: Maybe<Scalars['DateTime']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type DeviceListQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { deviceSummary: (
      { __typename?: 'DeviceSummary' }
      & Pick<DeviceSummary, 'totalDevices'>
    ), devices: (
      { __typename?: 'DeviceResults' }
      & Pick<DeviceResults, 'numOfResults'>
      & { results: Array<(
        { __typename?: 'Device' }
        & Pick<Device, 'id' | 'deviceSerialNumber' | 'deletedAt' | 'aircraftSerialNumber' | 'aircraftType' | 'currentCycleDataUsage' | 'iccid' | 'subscriptionStatus' | 'activated' | 'nextRenewal' | 'lastRenewal'>
        & { model: (
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'modelNumber'>
        ), dataPlan?: Maybe<(
          { __typename?: 'DataPlan' }
          & Pick<DataPlan, 'id' | 'name' | 'includedMb'>
        )>, enabledFeatures: Array<(
          { __typename?: 'ProductFeature' }
          & Pick<ProductFeature, 'id' | 'name' | 'featureNumber'>
        )> }
      )> }
    ) }
  ) }
);

export type DeviceSummaryQueryVariables = Exact<{
  accountId?: Maybe<Scalars['ID']>;
}>;


export type DeviceSummaryQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { deviceSummary: (
      { __typename?: 'DeviceSummary' }
      & Pick<DeviceSummary, 'pendingDevices' | 'activeDevices' | 'lockedDevices' | 'deactivatedDevices' | 'totalDevices' | 'dataOverages' | 'totalBytes'>
    ) }
  ) }
);

export type DeviceUpdatesQueryVariables = Exact<{
  accountId: Scalars['ID'];
  since: Scalars['DateTime'];
}>;


export type DeviceUpdatesQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { actions: Array<(
      { __typename?: 'CreateDeviceRequest' }
      & Pick<CreateDeviceRequest, 'modified'>
      & { device: (
        { __typename?: 'Device' }
        & DeviceActionMutableFieldsFragment
      ) }
      & DeviceActions_CreateDeviceRequest_Fragment
    ) | (
      { __typename?: 'DataPlanRequest' }
      & Pick<DataPlanRequest, 'modified'>
      & { device: (
        { __typename?: 'Device' }
        & DeviceActionMutableFieldsFragment
      ) }
      & DeviceActions_DataPlanRequest_Fragment
    ) | (
      { __typename?: 'DecommissionRequest' }
      & Pick<DecommissionRequest, 'modified'>
      & { device: (
        { __typename?: 'Device' }
        & DeviceActionMutableFieldsFragment
      ) }
      & DeviceActions_DecommissionRequest_Fragment
    ) | (
      { __typename?: 'FeatureRequest' }
      & Pick<FeatureRequest, 'modified'>
      & { device: (
        { __typename?: 'Device' }
        & DeviceActionMutableFieldsFragment
      ) }
      & DeviceActions_FeatureRequest_Fragment
    ) | (
      { __typename?: 'ProvisionRequest' }
      & Pick<ProvisionRequest, 'modified'>
      & { device: (
        { __typename?: 'Device' }
        & DeviceActionMutableFieldsFragment
      ) }
      & DeviceActions_ProvisionRequest_Fragment
    ) | (
      { __typename?: 'ReplaceSimRequest' }
      & Pick<ReplaceSimRequest, 'modified'>
      & { device: (
        { __typename?: 'Device' }
        & DeviceActionMutableFieldsFragment
      ) }
      & DeviceActions_ReplaceSimRequest_Fragment
    ) | (
      { __typename?: 'UpdateDeviceRequest' }
      & Pick<UpdateDeviceRequest, 'modified'>
      & { device: (
        { __typename?: 'Device' }
        & DeviceActionMutableFieldsFragment
      ) }
      & DeviceActions_UpdateDeviceRequest_Fragment
    )> }
  ) }
);

export type InvoicesQueryVariables = Exact<{
  accountId?: Maybe<Scalars['ID']>;
}>;


export type InvoicesQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'invoiceNumber' | 'billingStart' | 'billingStop' | 'invoiceDate' | 'invoiceUrl' | 'published'>
    )> }
  ) }
);

export type ListAccountUsersQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type ListAccountUsersQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { users: Array<(
      { __typename?: 'UserAccountEdge' }
      & Pick<UserAccountEdge, 'id' | 'role'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'email' | 'isRegistered'>
      ) }
    )> }
  ) }
);

export type ListApiCredentialsQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type ListApiCredentialsQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { apiCredentials: Array<(
      { __typename?: 'ApiCredential' }
      & Pick<ApiCredential, 'id' | 'clientId' | 'description' | 'active'>
    )> }
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'email' | 'isAstroAdmin'>
    & { accounts: Array<(
      { __typename?: 'UserAccountEdge' }
      & Pick<UserAccountEdge, 'role'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type NearingDataLimitQueryVariables = Exact<{
  accountId: Scalars['ID'];
}>;


export type NearingDataLimitQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
    & { devices: (
      { __typename?: 'DeviceResults' }
      & Pick<DeviceResults, 'numOfResults'>
      & { results: Array<(
        { __typename?: 'Device' }
        & Pick<Device, 'id' | 'iccid' | 'currentCycleDataUsage'>
        & { dataPlan?: Maybe<(
          { __typename?: 'DataPlan' }
          & Pick<DataPlan, 'name' | 'includedMb'>
        )> }
      )> }
    ) }
  ) }
);

export type ProductsAndDataPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsAndDataPlansQuery = (
  { __typename?: 'Query' }
  & { dataPlans: Array<(
    { __typename?: 'DataPlan' }
    & Pick<DataPlan, 'id' | 'name' | 'planNumber' | 'pricePerMonth' | 'includedMb' | 'overagePricePerMb' | 'annualFee' | 'renewalTimeInMonths'>
    & { accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
    )> }
  )>, products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'modelNumber' | 'description'>
    & { features: Array<(
      { __typename?: 'ProductFeature' }
      & Pick<ProductFeature, 'id' | 'name' | 'description' | 'featureNumber'>
    )> }
  )> }
);

export type TokenInfoQueryVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
}>;


export type TokenInfoQuery = (
  { __typename?: 'Query' }
  & { tokenInfo?: Maybe<(
    { __typename?: 'UserTokenInfo' }
    & Pick<UserTokenInfo, 'tokenIsExpired' | 'isRegistered'>
  )> }
);

export type VerifyIccidQueryVariables = Exact<{
  iccid: Scalars['ICCID'];
}>;


export type VerifyIccidQuery = (
  { __typename?: 'Query' }
  & { verifyIccid: Query['verifyIccid'] }
);

export const DeviceActionMutableFieldsFragmentDoc = gql`
    fragment deviceActionMutableFields on Device {
  id
  iccid
  operatorName
  deviceSerialNumber
  aircraftSerialNumber
  aircraftType
  subscriptionStatus
  pendingStatus
  deletedAt
  lastRenewal
  nextRenewal
  model {
    id
    name
    modelNumber
    description
  }
  dataPlan {
    id
    includedMb
    name
    planNumber
  }
  enabledFeatures {
    id
    name
    featureNumber
    description
  }
  account {
    id
    deviceSummary {
      pendingDevices
      activeDevices
      lockedDevices
      deactivatedDevices
      totalDevices
      totalBytes
      dataOverages
    }
  }
}
    `;
export const DeviceActionsFragmentDoc = gql`
    fragment deviceActions on DeviceAction {
  id
  __typename
  submitted
  completed
  ... on ProvisionRequest {
    action
    status
    errorMessage
  }
  ... on DataPlanRequest {
    dataPlan {
      id
      name
      planNumber
      includedMb
    }
  }
  ... on ReplaceSimRequest {
    message
  }
  ... on FeatureRequest {
    featureAdded
    feature {
      id
      featureNumber
      name
      description
    }
  }
}
    `;
export const DeviceActionResponseFragmentDoc = gql`
    fragment deviceActionResponse on DeviceActionResponse {
  ... on SuccessResponse {
    device {
      ...deviceActionMutableFields
    }
    response {
      ...deviceActions
    }
  }
  ... on ErrorResponse {
    error
  }
}
    ${DeviceActionMutableFieldsFragmentDoc}
${DeviceActionsFragmentDoc}`;
export const DeviceDetailsFragmentDoc = gql`
    fragment deviceDetails on Device {
  id
  pendingStatus
  iccid
  currentCycleDataUsage
  deviceSerialNumber
  currentCycleDataUsage
  subscriptionStatus
  aircraftSerialNumber
  aircraftType
  operatorName
  deletedAt
  dataPlan {
    id
    name
    planNumber
    includedMb
  }
  model {
    id
    name
    modelNumber
    description
    features {
      id
      featureNumber
      name
      description
    }
  }
  enabledFeatures {
    id
    featureNumber
    name
    description
  }
  created
  modified
  lastRenewal
  nextRenewal
}
    `;
export const ActivateDeviceDocument = gql`
    mutation ActivateDevice($device: DeviceLookup!, $planNumber: String!) {
  action: activateDevice(device: $device, planNumber: $planNumber) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type ActivateDeviceMutationFn = Apollo.MutationFunction<ActivateDeviceMutation, ActivateDeviceMutationVariables>;

/**
 * __useActivateDeviceMutation__
 *
 * To run a mutation, you first call `useActivateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateDeviceMutation, { data, loading, error }] = useActivateDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *      planNumber: // value for 'planNumber'
 *   },
 * });
 */
export function useActivateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<ActivateDeviceMutation, ActivateDeviceMutationVariables>) {
        return Apollo.useMutation<ActivateDeviceMutation, ActivateDeviceMutationVariables>(ActivateDeviceDocument, baseOptions);
      }
export type ActivateDeviceMutationHookResult = ReturnType<typeof useActivateDeviceMutation>;
export type ActivateDeviceMutationResult = Apollo.MutationResult<ActivateDeviceMutation>;
export type ActivateDeviceMutationOptions = Apollo.BaseMutationOptions<ActivateDeviceMutation, ActivateDeviceMutationVariables>;
export const AddAccountDocument = gql`
    mutation AddAccount($accountName: String!, $accountNumber: String!, $accountOwnerName: String!, $accountOwnerEmail: Email!) {
  createAccount(
    accountName: $accountName
    accountNumber: $accountNumber
    accountOwnerName: $accountOwnerName
    accountOwnerEmail: $accountOwnerEmail
  ) {
    id
    name
    accountNumber
  }
}
    `;
export type AddAccountMutationFn = Apollo.MutationFunction<AddAccountMutation, AddAccountMutationVariables>;

/**
 * __useAddAccountMutation__
 *
 * To run a mutation, you first call `useAddAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountMutation, { data, loading, error }] = useAddAccountMutation({
 *   variables: {
 *      accountName: // value for 'accountName'
 *      accountNumber: // value for 'accountNumber'
 *      accountOwnerName: // value for 'accountOwnerName'
 *      accountOwnerEmail: // value for 'accountOwnerEmail'
 *   },
 * });
 */
export function useAddAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddAccountMutation, AddAccountMutationVariables>) {
        return Apollo.useMutation<AddAccountMutation, AddAccountMutationVariables>(AddAccountDocument, baseOptions);
      }
export type AddAccountMutationHookResult = ReturnType<typeof useAddAccountMutation>;
export type AddAccountMutationResult = Apollo.MutationResult<AddAccountMutation>;
export type AddAccountMutationOptions = Apollo.BaseMutationOptions<AddAccountMutation, AddAccountMutationVariables>;
export const AddAccountUserDocument = gql`
    mutation AddAccountUser($accountId: ID!, $email: Email!, $name: String!, $accountAdmin: Boolean!) {
  addUser(
    accountId: $accountId
    name: $name
    email: $email
    accountAdmin: $accountAdmin
  ) {
    id
    name
    email
  }
}
    `;
export type AddAccountUserMutationFn = Apollo.MutationFunction<AddAccountUserMutation, AddAccountUserMutationVariables>;

/**
 * __useAddAccountUserMutation__
 *
 * To run a mutation, you first call `useAddAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountUserMutation, { data, loading, error }] = useAddAccountUserMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      accountAdmin: // value for 'accountAdmin'
 *   },
 * });
 */
export function useAddAccountUserMutation(baseOptions?: Apollo.MutationHookOptions<AddAccountUserMutation, AddAccountUserMutationVariables>) {
        return Apollo.useMutation<AddAccountUserMutation, AddAccountUserMutationVariables>(AddAccountUserDocument, baseOptions);
      }
export type AddAccountUserMutationHookResult = ReturnType<typeof useAddAccountUserMutation>;
export type AddAccountUserMutationResult = Apollo.MutationResult<AddAccountUserMutation>;
export type AddAccountUserMutationOptions = Apollo.BaseMutationOptions<AddAccountUserMutation, AddAccountUserMutationVariables>;
export const AddDataPlanDocument = gql`
    mutation AddDataPlan($name: String!, $planNumber: String!, $pricePerMonth: Float!, $includedMb: Float!, $overagePricePerMb: Float!, $annualFee: Float, $renewalTimeInMonths: Int, $accounts: [AccountInput]) {
  addDataPlan(
    name: $name
    planNumber: $planNumber
    pricePerMonth: $pricePerMonth
    includedMb: $includedMb
    overagePricePerMb: $overagePricePerMb
    annualFee: $annualFee
    renewalTimeInMonths: $renewalTimeInMonths
    accounts: $accounts
  ) {
    id
    name
    planNumber
    pricePerMonth
    includedMb
    overagePricePerMb
    annualFee
    renewalTimeInMonths
    accounts {
      id
      accountNumber
      name
    }
  }
}
    `;
export type AddDataPlanMutationFn = Apollo.MutationFunction<AddDataPlanMutation, AddDataPlanMutationVariables>;

/**
 * __useAddDataPlanMutation__
 *
 * To run a mutation, you first call `useAddDataPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDataPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDataPlanMutation, { data, loading, error }] = useAddDataPlanMutation({
 *   variables: {
 *      name: // value for 'name'
 *      planNumber: // value for 'planNumber'
 *      pricePerMonth: // value for 'pricePerMonth'
 *      includedMb: // value for 'includedMb'
 *      overagePricePerMb: // value for 'overagePricePerMb'
 *      annualFee: // value for 'annualFee'
 *      renewalTimeInMonths: // value for 'renewalTimeInMonths'
 *      accounts: // value for 'accounts'
 *   },
 * });
 */
export function useAddDataPlanMutation(baseOptions?: Apollo.MutationHookOptions<AddDataPlanMutation, AddDataPlanMutationVariables>) {
        return Apollo.useMutation<AddDataPlanMutation, AddDataPlanMutationVariables>(AddDataPlanDocument, baseOptions);
      }
export type AddDataPlanMutationHookResult = ReturnType<typeof useAddDataPlanMutation>;
export type AddDataPlanMutationResult = Apollo.MutationResult<AddDataPlanMutation>;
export type AddDataPlanMutationOptions = Apollo.BaseMutationOptions<AddDataPlanMutation, AddDataPlanMutationVariables>;
export const AddDeviceDocument = gql`
    mutation AddDevice($accountId: ID!, $iccid: ICCID!, $activate: Boolean!, $deviceModelNumber: String!, $deviceSerialNumber: String, $aircraftSerialNumber: String, $aircraftType: String, $operatorName: String, $dataPlanNumber: String, $optionalFeatureNumbers: [String!]) {
  action: addDevice(
    accountId: $accountId
    iccid: $iccid
    deviceModelNumber: $deviceModelNumber
    deviceSerialNumber: $deviceSerialNumber
    aircraftSerialNumber: $aircraftSerialNumber
    aircraftType: $aircraftType
    operatorName: $operatorName
    dataPlanNumber: $dataPlanNumber
    optionalFeatureNumbers: $optionalFeatureNumbers
    activate: $activate
  ) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type AddDeviceMutationFn = Apollo.MutationFunction<AddDeviceMutation, AddDeviceMutationVariables>;

/**
 * __useAddDeviceMutation__
 *
 * To run a mutation, you first call `useAddDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeviceMutation, { data, loading, error }] = useAddDeviceMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      iccid: // value for 'iccid'
 *      activate: // value for 'activate'
 *      deviceModelNumber: // value for 'deviceModelNumber'
 *      deviceSerialNumber: // value for 'deviceSerialNumber'
 *      aircraftSerialNumber: // value for 'aircraftSerialNumber'
 *      aircraftType: // value for 'aircraftType'
 *      operatorName: // value for 'operatorName'
 *      dataPlanNumber: // value for 'dataPlanNumber'
 *      optionalFeatureNumbers: // value for 'optionalFeatureNumbers'
 *   },
 * });
 */
export function useAddDeviceMutation(baseOptions?: Apollo.MutationHookOptions<AddDeviceMutation, AddDeviceMutationVariables>) {
        return Apollo.useMutation<AddDeviceMutation, AddDeviceMutationVariables>(AddDeviceDocument, baseOptions);
      }
export type AddDeviceMutationHookResult = ReturnType<typeof useAddDeviceMutation>;
export type AddDeviceMutationResult = Apollo.MutationResult<AddDeviceMutation>;
export type AddDeviceMutationOptions = Apollo.BaseMutationOptions<AddDeviceMutation, AddDeviceMutationVariables>;
export const AddFeatureDocument = gql`
    mutation AddFeature($accountId: ID!, $deviceId: ID!, $featureNumber: String!) {
  action: addFeature(
    device: {accountId: $accountId, deviceId: $deviceId}
    featureNumber: $featureNumber
  ) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type AddFeatureMutationFn = Apollo.MutationFunction<AddFeatureMutation, AddFeatureMutationVariables>;

/**
 * __useAddFeatureMutation__
 *
 * To run a mutation, you first call `useAddFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFeatureMutation, { data, loading, error }] = useAddFeatureMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      deviceId: // value for 'deviceId'
 *      featureNumber: // value for 'featureNumber'
 *   },
 * });
 */
export function useAddFeatureMutation(baseOptions?: Apollo.MutationHookOptions<AddFeatureMutation, AddFeatureMutationVariables>) {
        return Apollo.useMutation<AddFeatureMutation, AddFeatureMutationVariables>(AddFeatureDocument, baseOptions);
      }
export type AddFeatureMutationHookResult = ReturnType<typeof useAddFeatureMutation>;
export type AddFeatureMutationResult = Apollo.MutationResult<AddFeatureMutation>;
export type AddFeatureMutationOptions = Apollo.BaseMutationOptions<AddFeatureMutation, AddFeatureMutationVariables>;
export const ChangeDataPlanDocument = gql`
    mutation ChangeDataPlan($device: DeviceLookup!, $planNumber: String!) {
  action: changeDataPlan(device: $device, planNumber: $planNumber) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type ChangeDataPlanMutationFn = Apollo.MutationFunction<ChangeDataPlanMutation, ChangeDataPlanMutationVariables>;

/**
 * __useChangeDataPlanMutation__
 *
 * To run a mutation, you first call `useChangeDataPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDataPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDataPlanMutation, { data, loading, error }] = useChangeDataPlanMutation({
 *   variables: {
 *      device: // value for 'device'
 *      planNumber: // value for 'planNumber'
 *   },
 * });
 */
export function useChangeDataPlanMutation(baseOptions?: Apollo.MutationHookOptions<ChangeDataPlanMutation, ChangeDataPlanMutationVariables>) {
        return Apollo.useMutation<ChangeDataPlanMutation, ChangeDataPlanMutationVariables>(ChangeDataPlanDocument, baseOptions);
      }
export type ChangeDataPlanMutationHookResult = ReturnType<typeof useChangeDataPlanMutation>;
export type ChangeDataPlanMutationResult = Apollo.MutationResult<ChangeDataPlanMutation>;
export type ChangeDataPlanMutationOptions = Apollo.BaseMutationOptions<ChangeDataPlanMutation, ChangeDataPlanMutationVariables>;
export const ChangeNameDocument = gql`
    mutation ChangeName($name: String!) {
  updateUser(name: $name) {
    id
    name
  }
}
    `;
export type ChangeNameMutationFn = Apollo.MutationFunction<ChangeNameMutation, ChangeNameMutationVariables>;

/**
 * __useChangeNameMutation__
 *
 * To run a mutation, you first call `useChangeNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeNameMutation, { data, loading, error }] = useChangeNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useChangeNameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeNameMutation, ChangeNameMutationVariables>) {
        return Apollo.useMutation<ChangeNameMutation, ChangeNameMutationVariables>(ChangeNameDocument, baseOptions);
      }
export type ChangeNameMutationHookResult = ReturnType<typeof useChangeNameMutation>;
export type ChangeNameMutationResult = Apollo.MutationResult<ChangeNameMutation>;
export type ChangeNameMutationOptions = Apollo.BaseMutationOptions<ChangeNameMutation, ChangeNameMutationVariables>;
export const ChangeSimDocument = gql`
    mutation ChangeSim($device: DeviceLookup!, $newSim: ICCID!) {
  action: changeSim(device: $device, newSim: $newSim) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type ChangeSimMutationFn = Apollo.MutationFunction<ChangeSimMutation, ChangeSimMutationVariables>;

/**
 * __useChangeSimMutation__
 *
 * To run a mutation, you first call `useChangeSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSimMutation, { data, loading, error }] = useChangeSimMutation({
 *   variables: {
 *      device: // value for 'device'
 *      newSim: // value for 'newSim'
 *   },
 * });
 */
export function useChangeSimMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSimMutation, ChangeSimMutationVariables>) {
        return Apollo.useMutation<ChangeSimMutation, ChangeSimMutationVariables>(ChangeSimDocument, baseOptions);
      }
export type ChangeSimMutationHookResult = ReturnType<typeof useChangeSimMutation>;
export type ChangeSimMutationResult = Apollo.MutationResult<ChangeSimMutation>;
export type ChangeSimMutationOptions = Apollo.BaseMutationOptions<ChangeSimMutation, ChangeSimMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($token: String!) {
  confirmEmail(token: $token)
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, baseOptions);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const CreateApiCredentialsDocument = gql`
    mutation createApiCredentials($accountId: ID!, $description: String!) {
  createApiCredential(accountId: $accountId, description: $description) {
    secret
    apiCredentials {
      id
      clientId
    }
  }
}
    `;
export type CreateApiCredentialsMutationFn = Apollo.MutationFunction<CreateApiCredentialsMutation, CreateApiCredentialsMutationVariables>;

/**
 * __useCreateApiCredentialsMutation__
 *
 * To run a mutation, you first call `useCreateApiCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiCredentialsMutation, { data, loading, error }] = useCreateApiCredentialsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateApiCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiCredentialsMutation, CreateApiCredentialsMutationVariables>) {
        return Apollo.useMutation<CreateApiCredentialsMutation, CreateApiCredentialsMutationVariables>(CreateApiCredentialsDocument, baseOptions);
      }
export type CreateApiCredentialsMutationHookResult = ReturnType<typeof useCreateApiCredentialsMutation>;
export type CreateApiCredentialsMutationResult = Apollo.MutationResult<CreateApiCredentialsMutation>;
export type CreateApiCredentialsMutationOptions = Apollo.BaseMutationOptions<CreateApiCredentialsMutation, CreateApiCredentialsMutationVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($name: String!, $modelNumber: String!, $description: String, $features: [ProductFeatureInput]) {
  createProduct(
    name: $name
    modelNumber: $modelNumber
    description: $description
    features: $features
  ) {
    name
    modelNumber
    description
    features {
      id
      name
      description
    }
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      name: // value for 'name'
 *      modelNumber: // value for 'modelNumber'
 *      description: // value for 'description'
 *      features: // value for 'features'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const DeactivateDeviceDocument = gql`
    mutation DeactivateDevice($device: DeviceLookup!) {
  action: deactivateDevice(device: $device) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type DeactivateDeviceMutationFn = Apollo.MutationFunction<DeactivateDeviceMutation, DeactivateDeviceMutationVariables>;

/**
 * __useDeactivateDeviceMutation__
 *
 * To run a mutation, you first call `useDeactivateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateDeviceMutation, { data, loading, error }] = useDeactivateDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useDeactivateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateDeviceMutation, DeactivateDeviceMutationVariables>) {
        return Apollo.useMutation<DeactivateDeviceMutation, DeactivateDeviceMutationVariables>(DeactivateDeviceDocument, baseOptions);
      }
export type DeactivateDeviceMutationHookResult = ReturnType<typeof useDeactivateDeviceMutation>;
export type DeactivateDeviceMutationResult = Apollo.MutationResult<DeactivateDeviceMutation>;
export type DeactivateDeviceMutationOptions = Apollo.BaseMutationOptions<DeactivateDeviceMutation, DeactivateDeviceMutationVariables>;
export const DecommissionDeviceDocument = gql`
    mutation DecommissionDevice($device: DeviceLookup!) {
  action: decommissionDevice(device: $device) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type DecommissionDeviceMutationFn = Apollo.MutationFunction<DecommissionDeviceMutation, DecommissionDeviceMutationVariables>;

/**
 * __useDecommissionDeviceMutation__
 *
 * To run a mutation, you first call `useDecommissionDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecommissionDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decommissionDeviceMutation, { data, loading, error }] = useDecommissionDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useDecommissionDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DecommissionDeviceMutation, DecommissionDeviceMutationVariables>) {
        return Apollo.useMutation<DecommissionDeviceMutation, DecommissionDeviceMutationVariables>(DecommissionDeviceDocument, baseOptions);
      }
export type DecommissionDeviceMutationHookResult = ReturnType<typeof useDecommissionDeviceMutation>;
export type DecommissionDeviceMutationResult = Apollo.MutationResult<DecommissionDeviceMutation>;
export type DecommissionDeviceMutationOptions = Apollo.BaseMutationOptions<DecommissionDeviceMutation, DecommissionDeviceMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: Email!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const GenerateInvoiceDocument = gql`
    mutation GenerateInvoice($invoiceAdjustments: InvoiceAdjustments) {
  generateInvoice(invoiceAdjustments: $invoiceAdjustments)
}
    `;
export type GenerateInvoiceMutationFn = Apollo.MutationFunction<GenerateInvoiceMutation, GenerateInvoiceMutationVariables>;

/**
 * __useGenerateInvoiceMutation__
 *
 * To run a mutation, you first call `useGenerateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateInvoiceMutation, { data, loading, error }] = useGenerateInvoiceMutation({
 *   variables: {
 *      invoiceAdjustments: // value for 'invoiceAdjustments'
 *   },
 * });
 */
export function useGenerateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<GenerateInvoiceMutation, GenerateInvoiceMutationVariables>) {
        return Apollo.useMutation<GenerateInvoiceMutation, GenerateInvoiceMutationVariables>(GenerateInvoiceDocument, baseOptions);
      }
export type GenerateInvoiceMutationHookResult = ReturnType<typeof useGenerateInvoiceMutation>;
export type GenerateInvoiceMutationResult = Apollo.MutationResult<GenerateInvoiceMutation>;
export type GenerateInvoiceMutationOptions = Apollo.BaseMutationOptions<GenerateInvoiceMutation, GenerateInvoiceMutationVariables>;
export const LockDeviceDocument = gql`
    mutation LockDevice($device: DeviceLookup!) {
  action: lockDevice(device: $device) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type LockDeviceMutationFn = Apollo.MutationFunction<LockDeviceMutation, LockDeviceMutationVariables>;

/**
 * __useLockDeviceMutation__
 *
 * To run a mutation, you first call `useLockDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockDeviceMutation, { data, loading, error }] = useLockDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useLockDeviceMutation(baseOptions?: Apollo.MutationHookOptions<LockDeviceMutation, LockDeviceMutationVariables>) {
        return Apollo.useMutation<LockDeviceMutation, LockDeviceMutationVariables>(LockDeviceDocument, baseOptions);
      }
export type LockDeviceMutationHookResult = ReturnType<typeof useLockDeviceMutation>;
export type LockDeviceMutationResult = Apollo.MutationResult<LockDeviceMutation>;
export type LockDeviceMutationOptions = Apollo.BaseMutationOptions<LockDeviceMutation, LockDeviceMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: Email!, $password: String!) {
  login(email: $email, password: $password) {
    result
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RefreshDeviceDocument = gql`
    mutation RefreshDevice($device: DeviceLookup!) {
  action: refreshDevice(device: $device) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type RefreshDeviceMutationFn = Apollo.MutationFunction<RefreshDeviceMutation, RefreshDeviceMutationVariables>;

/**
 * __useRefreshDeviceMutation__
 *
 * To run a mutation, you first call `useRefreshDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshDeviceMutation, { data, loading, error }] = useRefreshDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useRefreshDeviceMutation(baseOptions?: Apollo.MutationHookOptions<RefreshDeviceMutation, RefreshDeviceMutationVariables>) {
        return Apollo.useMutation<RefreshDeviceMutation, RefreshDeviceMutationVariables>(RefreshDeviceDocument, baseOptions);
      }
export type RefreshDeviceMutationHookResult = ReturnType<typeof useRefreshDeviceMutation>;
export type RefreshDeviceMutationResult = Apollo.MutationResult<RefreshDeviceMutation>;
export type RefreshDeviceMutationOptions = Apollo.BaseMutationOptions<RefreshDeviceMutation, RefreshDeviceMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  __typename
  refreshToken {
    result
    token
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, baseOptions);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const RegisterAccountDocument = gql`
    mutation RegisterAccount($token: String!, $email: Email!, $newPassword: Password!, $name: String!) {
  registerAccount(
    token: $token
    email: $email
    newPassword: $newPassword
    name: $name
  ) {
    result
  }
}
    `;
export type RegisterAccountMutationFn = Apollo.MutationFunction<RegisterAccountMutation, RegisterAccountMutationVariables>;

/**
 * __useRegisterAccountMutation__
 *
 * To run a mutation, you first call `useRegisterAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAccountMutation, { data, loading, error }] = useRegisterAccountMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *      newPassword: // value for 'newPassword'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRegisterAccountMutation(baseOptions?: Apollo.MutationHookOptions<RegisterAccountMutation, RegisterAccountMutationVariables>) {
        return Apollo.useMutation<RegisterAccountMutation, RegisterAccountMutationVariables>(RegisterAccountDocument, baseOptions);
      }
export type RegisterAccountMutationHookResult = ReturnType<typeof useRegisterAccountMutation>;
export type RegisterAccountMutationResult = Apollo.MutationResult<RegisterAccountMutation>;
export type RegisterAccountMutationOptions = Apollo.BaseMutationOptions<RegisterAccountMutation, RegisterAccountMutationVariables>;
export const RemoveAccountUserDocument = gql`
    mutation RemoveAccountUser($accountId: ID!, $userId: ID!) {
  removeUser(accountId: $accountId, userId: $userId) {
    id
    users {
      id
      role
      user {
        id
        name
        email
      }
    }
  }
}
    `;
export type RemoveAccountUserMutationFn = Apollo.MutationFunction<RemoveAccountUserMutation, RemoveAccountUserMutationVariables>;

/**
 * __useRemoveAccountUserMutation__
 *
 * To run a mutation, you first call `useRemoveAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountUserMutation, { data, loading, error }] = useRemoveAccountUserMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveAccountUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAccountUserMutation, RemoveAccountUserMutationVariables>) {
        return Apollo.useMutation<RemoveAccountUserMutation, RemoveAccountUserMutationVariables>(RemoveAccountUserDocument, baseOptions);
      }
export type RemoveAccountUserMutationHookResult = ReturnType<typeof useRemoveAccountUserMutation>;
export type RemoveAccountUserMutationResult = Apollo.MutationResult<RemoveAccountUserMutation>;
export type RemoveAccountUserMutationOptions = Apollo.BaseMutationOptions<RemoveAccountUserMutation, RemoveAccountUserMutationVariables>;
export const ReplaceSimRequestDocument = gql`
    mutation ReplaceSimRequest($device: DeviceLookup!, $address: AddressInput!, $reason: String!) {
  action: replaceSim(device: $device, address: $address, reason: $reason) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type ReplaceSimRequestMutationFn = Apollo.MutationFunction<ReplaceSimRequestMutation, ReplaceSimRequestMutationVariables>;

/**
 * __useReplaceSimRequestMutation__
 *
 * To run a mutation, you first call `useReplaceSimRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceSimRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceSimRequestMutation, { data, loading, error }] = useReplaceSimRequestMutation({
 *   variables: {
 *      device: // value for 'device'
 *      address: // value for 'address'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useReplaceSimRequestMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceSimRequestMutation, ReplaceSimRequestMutationVariables>) {
        return Apollo.useMutation<ReplaceSimRequestMutation, ReplaceSimRequestMutationVariables>(ReplaceSimRequestDocument, baseOptions);
      }
export type ReplaceSimRequestMutationHookResult = ReturnType<typeof useReplaceSimRequestMutation>;
export type ReplaceSimRequestMutationResult = Apollo.MutationResult<ReplaceSimRequestMutation>;
export type ReplaceSimRequestMutationOptions = Apollo.BaseMutationOptions<ReplaceSimRequestMutation, ReplaceSimRequestMutationVariables>;
export const RequestAccessDocument = gql`
    mutation RequestAccess($email: Email!, $phone: String!, $organization: String!, $message: String) {
  action: requestAccess(
    email: $email
    phone: $phone
    organization: $organization
    message: $message
  )
}
    `;
export type RequestAccessMutationFn = Apollo.MutationFunction<RequestAccessMutation, RequestAccessMutationVariables>;

/**
 * __useRequestAccessMutation__
 *
 * To run a mutation, you first call `useRequestAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAccessMutation, { data, loading, error }] = useRequestAccessMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      organization: // value for 'organization'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useRequestAccessMutation(baseOptions?: Apollo.MutationHookOptions<RequestAccessMutation, RequestAccessMutationVariables>) {
        return Apollo.useMutation<RequestAccessMutation, RequestAccessMutationVariables>(RequestAccessDocument, baseOptions);
      }
export type RequestAccessMutationHookResult = ReturnType<typeof useRequestAccessMutation>;
export type RequestAccessMutationResult = Apollo.MutationResult<RequestAccessMutation>;
export type RequestAccessMutationOptions = Apollo.BaseMutationOptions<RequestAccessMutation, RequestAccessMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: Email!, $token: String!, $newPassword: Password!) {
  resetPassword(email: $email, token: $token, newPassword: $newPassword) {
    result
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RevokeApiCredentialDocument = gql`
    mutation revokeApiCredential($accountId: ID!, $apiCredentialId: ID!) {
  revokeApiCredential(accountId: $accountId, apiCredentialId: $apiCredentialId)
}
    `;
export type RevokeApiCredentialMutationFn = Apollo.MutationFunction<RevokeApiCredentialMutation, RevokeApiCredentialMutationVariables>;

/**
 * __useRevokeApiCredentialMutation__
 *
 * To run a mutation, you first call `useRevokeApiCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeApiCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeApiCredentialMutation, { data, loading, error }] = useRevokeApiCredentialMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      apiCredentialId: // value for 'apiCredentialId'
 *   },
 * });
 */
export function useRevokeApiCredentialMutation(baseOptions?: Apollo.MutationHookOptions<RevokeApiCredentialMutation, RevokeApiCredentialMutationVariables>) {
        return Apollo.useMutation<RevokeApiCredentialMutation, RevokeApiCredentialMutationVariables>(RevokeApiCredentialDocument, baseOptions);
      }
export type RevokeApiCredentialMutationHookResult = ReturnType<typeof useRevokeApiCredentialMutation>;
export type RevokeApiCredentialMutationResult = Apollo.MutationResult<RevokeApiCredentialMutation>;
export type RevokeApiCredentialMutationOptions = Apollo.BaseMutationOptions<RevokeApiCredentialMutation, RevokeApiCredentialMutationVariables>;
export const UnlockDeviceDocument = gql`
    mutation UnlockDevice($device: DeviceLookup!) {
  action: unlockDevice(device: $device) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type UnlockDeviceMutationFn = Apollo.MutationFunction<UnlockDeviceMutation, UnlockDeviceMutationVariables>;

/**
 * __useUnlockDeviceMutation__
 *
 * To run a mutation, you first call `useUnlockDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockDeviceMutation, { data, loading, error }] = useUnlockDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useUnlockDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UnlockDeviceMutation, UnlockDeviceMutationVariables>) {
        return Apollo.useMutation<UnlockDeviceMutation, UnlockDeviceMutationVariables>(UnlockDeviceDocument, baseOptions);
      }
export type UnlockDeviceMutationHookResult = ReturnType<typeof useUnlockDeviceMutation>;
export type UnlockDeviceMutationResult = Apollo.MutationResult<UnlockDeviceMutation>;
export type UnlockDeviceMutationOptions = Apollo.BaseMutationOptions<UnlockDeviceMutation, UnlockDeviceMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation updateAccount($accountId: ID!, $name: String, $primaryContact: AddressInput) {
  updateAccount(
    accountId: $accountId
    name: $name
    primaryContact: $primaryContact
  ) {
    id
    name
    primaryContact {
      id
      name1
      name2
      address1
      address2
      city
      postal
      state
      country
      phone
      email
    }
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      name: // value for 'name'
 *      primaryContact: // value for 'primaryContact'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const UpdateDataPlanDocument = gql`
    mutation UpdateDataPlan($id: ID!, $name: String!, $planNumber: String!, $pricePerMonth: Float!, $includedMb: Float!, $overagePricePerMb: Float!, $annualFee: Float, $renewalTimeInMonths: Int, $accounts: [AccountInput]) {
  updateDataPlan(
    id: $id
    name: $name
    planNumber: $planNumber
    pricePerMonth: $pricePerMonth
    includedMb: $includedMb
    overagePricePerMb: $overagePricePerMb
    annualFee: $annualFee
    renewalTimeInMonths: $renewalTimeInMonths
    accounts: $accounts
  ) {
    id
    name
    planNumber
    pricePerMonth
    includedMb
    overagePricePerMb
    annualFee
    renewalTimeInMonths
    accounts {
      id
      accountNumber
      name
    }
  }
}
    `;
export type UpdateDataPlanMutationFn = Apollo.MutationFunction<UpdateDataPlanMutation, UpdateDataPlanMutationVariables>;

/**
 * __useUpdateDataPlanMutation__
 *
 * To run a mutation, you first call `useUpdateDataPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataPlanMutation, { data, loading, error }] = useUpdateDataPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      planNumber: // value for 'planNumber'
 *      pricePerMonth: // value for 'pricePerMonth'
 *      includedMb: // value for 'includedMb'
 *      overagePricePerMb: // value for 'overagePricePerMb'
 *      annualFee: // value for 'annualFee'
 *      renewalTimeInMonths: // value for 'renewalTimeInMonths'
 *      accounts: // value for 'accounts'
 *   },
 * });
 */
export function useUpdateDataPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataPlanMutation, UpdateDataPlanMutationVariables>) {
        return Apollo.useMutation<UpdateDataPlanMutation, UpdateDataPlanMutationVariables>(UpdateDataPlanDocument, baseOptions);
      }
export type UpdateDataPlanMutationHookResult = ReturnType<typeof useUpdateDataPlanMutation>;
export type UpdateDataPlanMutationResult = Apollo.MutationResult<UpdateDataPlanMutation>;
export type UpdateDataPlanMutationOptions = Apollo.BaseMutationOptions<UpdateDataPlanMutation, UpdateDataPlanMutationVariables>;
export const UpdateDeviceDocument = gql`
    mutation UpdateDevice($device: DeviceLookup!, $deviceSerialNumber: String, $deviceModelNumber: String, $aircraftSerialNumber: String, $operatorName: String, $aircraftType: String, $nextRenewalYear: Int, $nextRenewalMonth: Int) {
  action: updateDevice(
    device: $device
    deviceSerialNumber: $deviceSerialNumber
    deviceModelNumber: $deviceModelNumber
    aircraftSerialNumber: $aircraftSerialNumber
    operatorName: $operatorName
    aircraftType: $aircraftType
    nextRenewalYear: $nextRenewalYear
    nextRenewalMonth: $nextRenewalMonth
  ) {
    ...deviceActionResponse
  }
}
    ${DeviceActionResponseFragmentDoc}`;
export type UpdateDeviceMutationFn = Apollo.MutationFunction<UpdateDeviceMutation, UpdateDeviceMutationVariables>;

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *      deviceSerialNumber: // value for 'deviceSerialNumber'
 *      deviceModelNumber: // value for 'deviceModelNumber'
 *      aircraftSerialNumber: // value for 'aircraftSerialNumber'
 *      operatorName: // value for 'operatorName'
 *      aircraftType: // value for 'aircraftType'
 *      nextRenewalYear: // value for 'nextRenewalYear'
 *      nextRenewalMonth: // value for 'nextRenewalMonth'
 *   },
 * });
 */
export function useUpdateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>) {
        return Apollo.useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(UpdateDeviceDocument, baseOptions);
      }
export type UpdateDeviceMutationHookResult = ReturnType<typeof useUpdateDeviceMutation>;
export type UpdateDeviceMutationResult = Apollo.MutationResult<UpdateDeviceMutation>;
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>;
export const UpdateEmailDocument = gql`
    mutation UpdateEmail($newEmail: Email!) {
  updateEmail(newEmail: $newEmail)
}
    `;
export type UpdateEmailMutationFn = Apollo.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>;

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      newEmail: // value for 'newEmail'
 *   },
 * });
 */
export function useUpdateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>) {
        return Apollo.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, baseOptions);
      }
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>;
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>;
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>;
export const UpdateExpiredPasswordDocument = gql`
    mutation UpdateExpiredPassword($email: Email!, $oldPassword: String!, $newPassword: Password!) {
  updateExpiredPassword(
    email: $email
    oldPassword: $oldPassword
    newPassword: $newPassword
  ) {
    result
  }
}
    `;
export type UpdateExpiredPasswordMutationFn = Apollo.MutationFunction<UpdateExpiredPasswordMutation, UpdateExpiredPasswordMutationVariables>;

/**
 * __useUpdateExpiredPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateExpiredPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpiredPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpiredPasswordMutation, { data, loading, error }] = useUpdateExpiredPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdateExpiredPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpiredPasswordMutation, UpdateExpiredPasswordMutationVariables>) {
        return Apollo.useMutation<UpdateExpiredPasswordMutation, UpdateExpiredPasswordMutationVariables>(UpdateExpiredPasswordDocument, baseOptions);
      }
export type UpdateExpiredPasswordMutationHookResult = ReturnType<typeof useUpdateExpiredPasswordMutation>;
export type UpdateExpiredPasswordMutationResult = Apollo.MutationResult<UpdateExpiredPasswordMutation>;
export type UpdateExpiredPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateExpiredPasswordMutation, UpdateExpiredPasswordMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($oldPassword: String!, $newPassword: Password!) {
  updatePassword(oldPassword: $oldPassword, newPassword: $newPassword)
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, baseOptions);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($productId: ID!, $name: String!, $modelNumber: String!, $description: String, $features: [ProductFeatureInput]) {
  updateProduct(
    productId: $productId
    name: $name
    modelNumber: $modelNumber
    description: $description
    features: $features
  ) {
    name
    modelNumber
    description
    features {
      id
      name
      description
    }
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      name: // value for 'name'
 *      modelNumber: // value for 'modelNumber'
 *      description: // value for 'description'
 *      features: // value for 'features'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const AccountDetailsDocument = gql`
    query accountDetails($accountId: ID) {
  account(accountId: $accountId) {
    id
    accountNumber
    name
    primaryContact {
      id
      name1
      name2
      address1
      address2
      city
      postal
      state
      country
      phone
      email
    }
  }
}
    `;

/**
 * __useAccountDetailsQuery__
 *
 * To run a query within a React component, call `useAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountDetailsQuery(baseOptions?: Apollo.QueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
        return Apollo.useQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, baseOptions);
      }
export function useAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
          return Apollo.useLazyQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, baseOptions);
        }
export type AccountDetailsQueryHookResult = ReturnType<typeof useAccountDetailsQuery>;
export type AccountDetailsLazyQueryHookResult = ReturnType<typeof useAccountDetailsLazyQuery>;
export type AccountDetailsQueryResult = Apollo.QueryResult<AccountDetailsQuery, AccountDetailsQueryVariables>;
export const AdminAccountListDocument = gql`
    query AdminAccountList {
  accounts {
    id
    name
    accountNumber
    isDeactivated
  }
}
    `;

/**
 * __useAdminAccountListQuery__
 *
 * To run a query within a React component, call `useAdminAccountListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAccountListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAccountListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminAccountListQuery(baseOptions?: Apollo.QueryHookOptions<AdminAccountListQuery, AdminAccountListQueryVariables>) {
        return Apollo.useQuery<AdminAccountListQuery, AdminAccountListQueryVariables>(AdminAccountListDocument, baseOptions);
      }
export function useAdminAccountListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminAccountListQuery, AdminAccountListQueryVariables>) {
          return Apollo.useLazyQuery<AdminAccountListQuery, AdminAccountListQueryVariables>(AdminAccountListDocument, baseOptions);
        }
export type AdminAccountListQueryHookResult = ReturnType<typeof useAdminAccountListQuery>;
export type AdminAccountListLazyQueryHookResult = ReturnType<typeof useAdminAccountListLazyQuery>;
export type AdminAccountListQueryResult = Apollo.QueryResult<AdminAccountListQuery, AdminAccountListQueryVariables>;
export const DataPlansByAccountNumberDocument = gql`
    query DataPlansByAccountNumber($accountNumber: String!) {
  dataPlansByAccountNumber(accountNumber: $accountNumber) {
    id
    name
    planNumber
    pricePerMonth
    includedMb
    annualFee
    overagePricePerMb
  }
}
    `;

/**
 * __useDataPlansByAccountNumberQuery__
 *
 * To run a query within a React component, call `useDataPlansByAccountNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPlansByAccountNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPlansByAccountNumberQuery({
 *   variables: {
 *      accountNumber: // value for 'accountNumber'
 *   },
 * });
 */
export function useDataPlansByAccountNumberQuery(baseOptions: Apollo.QueryHookOptions<DataPlansByAccountNumberQuery, DataPlansByAccountNumberQueryVariables>) {
        return Apollo.useQuery<DataPlansByAccountNumberQuery, DataPlansByAccountNumberQueryVariables>(DataPlansByAccountNumberDocument, baseOptions);
      }
export function useDataPlansByAccountNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataPlansByAccountNumberQuery, DataPlansByAccountNumberQueryVariables>) {
          return Apollo.useLazyQuery<DataPlansByAccountNumberQuery, DataPlansByAccountNumberQueryVariables>(DataPlansByAccountNumberDocument, baseOptions);
        }
export type DataPlansByAccountNumberQueryHookResult = ReturnType<typeof useDataPlansByAccountNumberQuery>;
export type DataPlansByAccountNumberLazyQueryHookResult = ReturnType<typeof useDataPlansByAccountNumberLazyQuery>;
export type DataPlansByAccountNumberQueryResult = Apollo.QueryResult<DataPlansByAccountNumberQuery, DataPlansByAccountNumberQueryVariables>;
export const DeviceActivationNeededDocument = gql`
    query DeviceActivationNeeded($accountId: ID) {
  account: account(accountId: $accountId) {
    id
    devices(filter: {subscriptionStatus: Pending}) {
      numOfResults
      results {
        id
        iccid
        subscriptionStatus
        dataPlan {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useDeviceActivationNeededQuery__
 *
 * To run a query within a React component, call `useDeviceActivationNeededQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceActivationNeededQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceActivationNeededQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeviceActivationNeededQuery(baseOptions?: Apollo.QueryHookOptions<DeviceActivationNeededQuery, DeviceActivationNeededQueryVariables>) {
        return Apollo.useQuery<DeviceActivationNeededQuery, DeviceActivationNeededQueryVariables>(DeviceActivationNeededDocument, baseOptions);
      }
export function useDeviceActivationNeededLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceActivationNeededQuery, DeviceActivationNeededQueryVariables>) {
          return Apollo.useLazyQuery<DeviceActivationNeededQuery, DeviceActivationNeededQueryVariables>(DeviceActivationNeededDocument, baseOptions);
        }
export type DeviceActivationNeededQueryHookResult = ReturnType<typeof useDeviceActivationNeededQuery>;
export type DeviceActivationNeededLazyQueryHookResult = ReturnType<typeof useDeviceActivationNeededLazyQuery>;
export type DeviceActivationNeededQueryResult = Apollo.QueryResult<DeviceActivationNeededQuery, DeviceActivationNeededQueryVariables>;
export const DeviceDataUsageDocument = gql`
    query DeviceDataUsage($accountId: ID!, $deviceId: ID!, $start: DateTime!, $stop: DateTime!, $interval: TimeInterval!) {
  account: account(accountId: $accountId) {
    id
    device(deviceId: $deviceId) {
      id
      dataUsage: dataUsage(start: $start, stop: $stop, interval: $interval) {
        start
        stop
        interval
        series
        sum
      }
    }
  }
}
    `;

/**
 * __useDeviceDataUsageQuery__
 *
 * To run a query within a React component, call `useDeviceDataUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceDataUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceDataUsageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      deviceId: // value for 'deviceId'
 *      start: // value for 'start'
 *      stop: // value for 'stop'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useDeviceDataUsageQuery(baseOptions: Apollo.QueryHookOptions<DeviceDataUsageQuery, DeviceDataUsageQueryVariables>) {
        return Apollo.useQuery<DeviceDataUsageQuery, DeviceDataUsageQueryVariables>(DeviceDataUsageDocument, baseOptions);
      }
export function useDeviceDataUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceDataUsageQuery, DeviceDataUsageQueryVariables>) {
          return Apollo.useLazyQuery<DeviceDataUsageQuery, DeviceDataUsageQueryVariables>(DeviceDataUsageDocument, baseOptions);
        }
export type DeviceDataUsageQueryHookResult = ReturnType<typeof useDeviceDataUsageQuery>;
export type DeviceDataUsageLazyQueryHookResult = ReturnType<typeof useDeviceDataUsageLazyQuery>;
export type DeviceDataUsageQueryResult = Apollo.QueryResult<DeviceDataUsageQuery, DeviceDataUsageQueryVariables>;
export const DeviceDetailsDocument = gql`
    query DeviceDetails($accountId: ID!, $deviceId: ID!) {
  account: account(accountId: $accountId) {
    id
    device(deviceId: $deviceId) {
      ...deviceDetails
    }
  }
}
    ${DeviceDetailsFragmentDoc}`;

/**
 * __useDeviceDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceDetailsQuery, DeviceDetailsQueryVariables>) {
        return Apollo.useQuery<DeviceDetailsQuery, DeviceDetailsQueryVariables>(DeviceDetailsDocument, baseOptions);
      }
export function useDeviceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceDetailsQuery, DeviceDetailsQueryVariables>) {
          return Apollo.useLazyQuery<DeviceDetailsQuery, DeviceDetailsQueryVariables>(DeviceDetailsDocument, baseOptions);
        }
export type DeviceDetailsQueryHookResult = ReturnType<typeof useDeviceDetailsQuery>;
export type DeviceDetailsLazyQueryHookResult = ReturnType<typeof useDeviceDetailsLazyQuery>;
export type DeviceDetailsQueryResult = Apollo.QueryResult<DeviceDetailsQuery, DeviceDetailsQueryVariables>;
export const DeviceFeatureFileDocument = gql`
    query DeviceFeatureFile($accountId: ID!, $deviceId: ID!) {
  account: account(accountId: $accountId) {
    id
    device(deviceId: $deviceId) {
      id
      featureFile
    }
  }
}
    `;

/**
 * __useDeviceFeatureFileQuery__
 *
 * To run a query within a React component, call `useDeviceFeatureFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceFeatureFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceFeatureFileQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceFeatureFileQuery(baseOptions: Apollo.QueryHookOptions<DeviceFeatureFileQuery, DeviceFeatureFileQueryVariables>) {
        return Apollo.useQuery<DeviceFeatureFileQuery, DeviceFeatureFileQueryVariables>(DeviceFeatureFileDocument, baseOptions);
      }
export function useDeviceFeatureFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceFeatureFileQuery, DeviceFeatureFileQueryVariables>) {
          return Apollo.useLazyQuery<DeviceFeatureFileQuery, DeviceFeatureFileQueryVariables>(DeviceFeatureFileDocument, baseOptions);
        }
export type DeviceFeatureFileQueryHookResult = ReturnType<typeof useDeviceFeatureFileQuery>;
export type DeviceFeatureFileLazyQueryHookResult = ReturnType<typeof useDeviceFeatureFileLazyQuery>;
export type DeviceFeatureFileQueryResult = Apollo.QueryResult<DeviceFeatureFileQuery, DeviceFeatureFileQueryVariables>;
export const DeviceListDocument = gql`
    query DeviceList($accountId: ID!, $sd: SortDirection, $sf: DeviceSortFields, $ss: [SubscriptionStatus!], $feat: [String!], $dumax: Float, $dumin: Float, $actStart: DateTime, $actEnd: DateTime, $skip: Int = 0, $take: Int = 15, $search: String) {
  account: account(accountId: $accountId) {
    id
    deviceSummary {
      totalDevices
    }
    devices(
      filter: {subscriptionStatus: $ss, enabledFeatures: $feat, minDataUsage: $dumin, maxDataUsage: $dumax, activationDate: {from: $actStart, to: $actEnd}}
      sortBy: {direction: $sd, field: $sf}
      skip: $skip
      take: $take
      search: $search
    ) {
      numOfResults
      results {
        id
        deviceSerialNumber
        model {
          id
          name
          modelNumber
        }
        dataPlan {
          id
          name
          includedMb
        }
        enabledFeatures {
          id
          name
          featureNumber
        }
        deletedAt
        aircraftSerialNumber
        aircraftType
        currentCycleDataUsage
        iccid
        subscriptionStatus
        activated
        nextRenewal
        lastRenewal
      }
    }
  }
}
    `;

/**
 * __useDeviceListQuery__
 *
 * To run a query within a React component, call `useDeviceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceListQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sd: // value for 'sd'
 *      sf: // value for 'sf'
 *      ss: // value for 'ss'
 *      feat: // value for 'feat'
 *      dumax: // value for 'dumax'
 *      dumin: // value for 'dumin'
 *      actStart: // value for 'actStart'
 *      actEnd: // value for 'actEnd'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDeviceListQuery(baseOptions: Apollo.QueryHookOptions<DeviceListQuery, DeviceListQueryVariables>) {
        return Apollo.useQuery<DeviceListQuery, DeviceListQueryVariables>(DeviceListDocument, baseOptions);
      }
export function useDeviceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceListQuery, DeviceListQueryVariables>) {
          return Apollo.useLazyQuery<DeviceListQuery, DeviceListQueryVariables>(DeviceListDocument, baseOptions);
        }
export type DeviceListQueryHookResult = ReturnType<typeof useDeviceListQuery>;
export type DeviceListLazyQueryHookResult = ReturnType<typeof useDeviceListLazyQuery>;
export type DeviceListQueryResult = Apollo.QueryResult<DeviceListQuery, DeviceListQueryVariables>;
export const DeviceSummaryDocument = gql`
    query deviceSummary($accountId: ID) {
  account(accountId: $accountId) {
    id
    deviceSummary {
      pendingDevices
      activeDevices
      lockedDevices
      deactivatedDevices
      totalDevices
      dataOverages
      totalBytes
    }
  }
}
    `;

/**
 * __useDeviceSummaryQuery__
 *
 * To run a query within a React component, call `useDeviceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceSummaryQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeviceSummaryQuery(baseOptions?: Apollo.QueryHookOptions<DeviceSummaryQuery, DeviceSummaryQueryVariables>) {
        return Apollo.useQuery<DeviceSummaryQuery, DeviceSummaryQueryVariables>(DeviceSummaryDocument, baseOptions);
      }
export function useDeviceSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceSummaryQuery, DeviceSummaryQueryVariables>) {
          return Apollo.useLazyQuery<DeviceSummaryQuery, DeviceSummaryQueryVariables>(DeviceSummaryDocument, baseOptions);
        }
export type DeviceSummaryQueryHookResult = ReturnType<typeof useDeviceSummaryQuery>;
export type DeviceSummaryLazyQueryHookResult = ReturnType<typeof useDeviceSummaryLazyQuery>;
export type DeviceSummaryQueryResult = Apollo.QueryResult<DeviceSummaryQuery, DeviceSummaryQueryVariables>;
export const DeviceUpdatesDocument = gql`
    query DeviceUpdates($accountId: ID!, $since: DateTime!) {
  account: account(accountId: $accountId) {
    id
    actions(since: $since) {
      ...deviceActions
      modified
      device {
        ...deviceActionMutableFields
      }
    }
  }
}
    ${DeviceActionsFragmentDoc}
${DeviceActionMutableFieldsFragmentDoc}`;

/**
 * __useDeviceUpdatesQuery__
 *
 * To run a query within a React component, call `useDeviceUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceUpdatesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      since: // value for 'since'
 *   },
 * });
 */
export function useDeviceUpdatesQuery(baseOptions: Apollo.QueryHookOptions<DeviceUpdatesQuery, DeviceUpdatesQueryVariables>) {
        return Apollo.useQuery<DeviceUpdatesQuery, DeviceUpdatesQueryVariables>(DeviceUpdatesDocument, baseOptions);
      }
export function useDeviceUpdatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceUpdatesQuery, DeviceUpdatesQueryVariables>) {
          return Apollo.useLazyQuery<DeviceUpdatesQuery, DeviceUpdatesQueryVariables>(DeviceUpdatesDocument, baseOptions);
        }
export type DeviceUpdatesQueryHookResult = ReturnType<typeof useDeviceUpdatesQuery>;
export type DeviceUpdatesLazyQueryHookResult = ReturnType<typeof useDeviceUpdatesLazyQuery>;
export type DeviceUpdatesQueryResult = Apollo.QueryResult<DeviceUpdatesQuery, DeviceUpdatesQueryVariables>;
export const InvoicesDocument = gql`
    query Invoices($accountId: ID) {
  account(accountId: $accountId) {
    id
    invoices {
      id
      invoiceNumber
      billingStart
      billingStop
      invoiceDate
      invoiceUrl
      published
    }
  }
}
    `;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, baseOptions);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, baseOptions);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const ListAccountUsersDocument = gql`
    query ListAccountUsers($accountId: ID!) {
  account: account(accountId: $accountId) {
    id
    users {
      id
      role
      user {
        id
        name
        email
        isRegistered
      }
    }
  }
}
    `;

/**
 * __useListAccountUsersQuery__
 *
 * To run a query within a React component, call `useListAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountUsersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useListAccountUsersQuery(baseOptions: Apollo.QueryHookOptions<ListAccountUsersQuery, ListAccountUsersQueryVariables>) {
        return Apollo.useQuery<ListAccountUsersQuery, ListAccountUsersQueryVariables>(ListAccountUsersDocument, baseOptions);
      }
export function useListAccountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAccountUsersQuery, ListAccountUsersQueryVariables>) {
          return Apollo.useLazyQuery<ListAccountUsersQuery, ListAccountUsersQueryVariables>(ListAccountUsersDocument, baseOptions);
        }
export type ListAccountUsersQueryHookResult = ReturnType<typeof useListAccountUsersQuery>;
export type ListAccountUsersLazyQueryHookResult = ReturnType<typeof useListAccountUsersLazyQuery>;
export type ListAccountUsersQueryResult = Apollo.QueryResult<ListAccountUsersQuery, ListAccountUsersQueryVariables>;
export const ListApiCredentialsDocument = gql`
    query listApiCredentials($accountId: ID!) {
  account: account(accountId: $accountId) {
    id
    apiCredentials {
      id
      clientId
      description
      active
    }
  }
}
    `;

/**
 * __useListApiCredentialsQuery__
 *
 * To run a query within a React component, call `useListApiCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApiCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApiCredentialsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useListApiCredentialsQuery(baseOptions: Apollo.QueryHookOptions<ListApiCredentialsQuery, ListApiCredentialsQueryVariables>) {
        return Apollo.useQuery<ListApiCredentialsQuery, ListApiCredentialsQueryVariables>(ListApiCredentialsDocument, baseOptions);
      }
export function useListApiCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListApiCredentialsQuery, ListApiCredentialsQueryVariables>) {
          return Apollo.useLazyQuery<ListApiCredentialsQuery, ListApiCredentialsQueryVariables>(ListApiCredentialsDocument, baseOptions);
        }
export type ListApiCredentialsQueryHookResult = ReturnType<typeof useListApiCredentialsQuery>;
export type ListApiCredentialsLazyQueryHookResult = ReturnType<typeof useListApiCredentialsLazyQuery>;
export type ListApiCredentialsQueryResult = Apollo.QueryResult<ListApiCredentialsQuery, ListApiCredentialsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    name
    email
    isAstroAdmin
    accounts {
      role
      account {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const NearingDataLimitDocument = gql`
    query NearingDataLimit($accountId: ID!) {
  account: account(accountId: $accountId) {
    id
    devices(
      filter: {minDataUsage: 90}
      sortBy: {direction: Descending, field: DataUsage}
    ) {
      numOfResults
      results {
        id
        iccid
        dataPlan {
          name
          includedMb
        }
        currentCycleDataUsage
      }
    }
  }
}
    `;

/**
 * __useNearingDataLimitQuery__
 *
 * To run a query within a React component, call `useNearingDataLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useNearingDataLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNearingDataLimitQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useNearingDataLimitQuery(baseOptions: Apollo.QueryHookOptions<NearingDataLimitQuery, NearingDataLimitQueryVariables>) {
        return Apollo.useQuery<NearingDataLimitQuery, NearingDataLimitQueryVariables>(NearingDataLimitDocument, baseOptions);
      }
export function useNearingDataLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NearingDataLimitQuery, NearingDataLimitQueryVariables>) {
          return Apollo.useLazyQuery<NearingDataLimitQuery, NearingDataLimitQueryVariables>(NearingDataLimitDocument, baseOptions);
        }
export type NearingDataLimitQueryHookResult = ReturnType<typeof useNearingDataLimitQuery>;
export type NearingDataLimitLazyQueryHookResult = ReturnType<typeof useNearingDataLimitLazyQuery>;
export type NearingDataLimitQueryResult = Apollo.QueryResult<NearingDataLimitQuery, NearingDataLimitQueryVariables>;
export const ProductsAndDataPlansDocument = gql`
    query ProductsAndDataPlans {
  dataPlans: dataPlans {
    id
    name
    planNumber
    pricePerMonth
    includedMb
    overagePricePerMb
    annualFee
    renewalTimeInMonths
    accounts {
      id
      name
    }
  }
  products: catalog {
    id
    name
    modelNumber
    description
    features {
      id
      name
      description
      featureNumber
    }
  }
}
    `;

/**
 * __useProductsAndDataPlansQuery__
 *
 * To run a query within a React component, call `useProductsAndDataPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsAndDataPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsAndDataPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsAndDataPlansQuery(baseOptions?: Apollo.QueryHookOptions<ProductsAndDataPlansQuery, ProductsAndDataPlansQueryVariables>) {
        return Apollo.useQuery<ProductsAndDataPlansQuery, ProductsAndDataPlansQueryVariables>(ProductsAndDataPlansDocument, baseOptions);
      }
export function useProductsAndDataPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsAndDataPlansQuery, ProductsAndDataPlansQueryVariables>) {
          return Apollo.useLazyQuery<ProductsAndDataPlansQuery, ProductsAndDataPlansQueryVariables>(ProductsAndDataPlansDocument, baseOptions);
        }
export type ProductsAndDataPlansQueryHookResult = ReturnType<typeof useProductsAndDataPlansQuery>;
export type ProductsAndDataPlansLazyQueryHookResult = ReturnType<typeof useProductsAndDataPlansLazyQuery>;
export type ProductsAndDataPlansQueryResult = Apollo.QueryResult<ProductsAndDataPlansQuery, ProductsAndDataPlansQueryVariables>;
export const TokenInfoDocument = gql`
    query TokenInfo($email: String!, $token: String!) {
  tokenInfo: tokenInfo(email: $email, token: $token) {
    tokenIsExpired
    isRegistered
  }
}
    `;

/**
 * __useTokenInfoQuery__
 *
 * To run a query within a React component, call `useTokenInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenInfoQuery({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTokenInfoQuery(baseOptions: Apollo.QueryHookOptions<TokenInfoQuery, TokenInfoQueryVariables>) {
        return Apollo.useQuery<TokenInfoQuery, TokenInfoQueryVariables>(TokenInfoDocument, baseOptions);
      }
export function useTokenInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenInfoQuery, TokenInfoQueryVariables>) {
          return Apollo.useLazyQuery<TokenInfoQuery, TokenInfoQueryVariables>(TokenInfoDocument, baseOptions);
        }
export type TokenInfoQueryHookResult = ReturnType<typeof useTokenInfoQuery>;
export type TokenInfoLazyQueryHookResult = ReturnType<typeof useTokenInfoLazyQuery>;
export type TokenInfoQueryResult = Apollo.QueryResult<TokenInfoQuery, TokenInfoQueryVariables>;
export const VerifyIccidDocument = gql`
    query verifyIccid($iccid: ICCID!) {
  verifyIccid: verifyIccid(iccid: $iccid)
}
    `;

/**
 * __useVerifyIccidQuery__
 *
 * To run a query within a React component, call `useVerifyIccidQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyIccidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyIccidQuery({
 *   variables: {
 *      iccid: // value for 'iccid'
 *   },
 * });
 */
export function useVerifyIccidQuery(baseOptions: Apollo.QueryHookOptions<VerifyIccidQuery, VerifyIccidQueryVariables>) {
        return Apollo.useQuery<VerifyIccidQuery, VerifyIccidQueryVariables>(VerifyIccidDocument, baseOptions);
      }
export function useVerifyIccidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyIccidQuery, VerifyIccidQueryVariables>) {
          return Apollo.useLazyQuery<VerifyIccidQuery, VerifyIccidQueryVariables>(VerifyIccidDocument, baseOptions);
        }
export type VerifyIccidQueryHookResult = ReturnType<typeof useVerifyIccidQuery>;
export type VerifyIccidLazyQueryHookResult = ReturnType<typeof useVerifyIccidLazyQuery>;
export type VerifyIccidQueryResult = Apollo.QueryResult<VerifyIccidQuery, VerifyIccidQueryVariables>;