const TailFinReversedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 236 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_142_316)">
        <path
          d="M12.2 168.9H14.9C61.1 168.9 107.3 168.9 153.5 168.9C153.8 168.9 151 168.9 154.4 168.9C157.8 168.9 158 166.8 158.7 166.7C158.9 165.5 235.5 3.7 235.5 3.9C235.7 3.4 235.7 3.2 235.8 2.9C236 1.9 235.6 0 232.6 0C206.2 0 177.6 0 151.2 0C140.9 0 2.79999 148 1.89999 149.3C-4.30001 161.4 5.59998 168.9 12.2 168.9Z"
          fill="white"
        />
        <mask
          id="mask0_142_316"
          maskUnits="userSpaceOnUse"
          x="-1"
          y="0"
          width="237"
          height="169"
        >
          <path
            d="M12.2 168.9H14.9C61.1 168.9 107.3 168.9 153.5 168.9C153.8 168.9 151 168.9 154.4 168.9C157.8 168.9 158 166.8 158.7 166.7C158.9 165.5 235.5 3.7 235.5 3.9C235.7 3.4 235.7 3.2 235.8 2.9C236 1.9 235.6 0 232.6 0C206.2 0 177.6 0 151.2 0C151.2 0 150.5 0 151.2 0C140.9 0 2.79999 148 1.89999 149.3C-4.30001 161.4 5.59998 168.9 12.2 168.9Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_142_316)">
          <g opacity="0.7">
            <path
              d="M173.8 213.4L306.8 -26.6L138.3 -120L5.2998 119.9L173.8 213.4Z"
              fill="url(#paint0_linear_142_316)"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_142_316"
          x1="89.4749"
          y1="166.619"
          x2="222.554"
          y2="-73.3351"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.19" stop-color="#FCFCFC" />
          <stop offset="0.32" stop-color="#F2F2F2" />
          <stop offset="0.44" stop-color="#E3E3E3" />
          <stop offset="0.55" stop-color="#CDCDCD" />
          <stop offset="0.64" stop-color="#B0B0B0" />
          <stop offset="0.74" stop-color="#8D8D8D" />
          <stop offset="0.83" stop-color="#646464" />
          <stop offset="0.92" stop-color="#353535" />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_142_316">
          <rect
            width="235.8"
            height="168.9"
            fill="white"
            transform="matrix(-1 0 0 1 235.8 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TailFinReversedIcon;
